import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"

import {
  SuccessionMatrixSettings,
  UpdateCompanySuccessionMatrixSettingsInputObject,
} from "types/graphql.d"
import { MatrixGridItem } from "v2/react/components/successionSettings/MatrixGridItem"
import { basePalettes } from "v2/react/components/successionSettings/palettes"
import { SelectDropdown } from "v2/react/components/successionSettings/SelectDropdown"
import { InputErrorBanner } from "v2/react/shared/forms/InputErrorBanner"
import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { prepareIconClass } from "v2/react/utils/misc"
import { useSaveSuccessionMatrixSettingsMutation } from "v2/redux/GraphqlApi"
import {
  AxisModeType,
  CardType,
  GridItem,
  PaletteSchema,
  PaletteType,
  setAxisMode,
  setCardType,
  setGridItems,
  setPalette,
  updateGridItemColor,
  updateGridItemLabel,
  updatePalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface SelectOption {
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  text: CardType
}
interface PaletteSelection {
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  palette: string[]
  text: PaletteType
}

interface Props {
  isOpen: boolean
  onClose: () => void
  matrix: SuccessionMatrixSettings
}

function SuccessionMatrixModal({ isOpen, onClose, matrix }: Props) {
  const dispatch = useAppDispatch()
  const [saveMatrixSettings, { isLoading }] = useSaveSuccessionMatrixSettingsMutation()
  const [errors, setErrors] = useState<string[]>([])
  const labelErrors = useAppSelector((state) => state.matrix.labelErrors)
  const cardOptions = useAppSelector((state) => state.matrix.cardOptions)
  const viewablePaletteOptions = useAppSelector((state) => state.matrix.viewablePaletteOptions)

  const gridItems = useAppSelector((state) => state.matrix.gridItems)
  const chosenCardStyle: CardType = useAppSelector((state) => state.matrix.cardType)
  const chosenPaletteStyle: PaletteType = useAppSelector((state) => state.matrix.paletteType)
  const chosenAxisMode: AxisModeType = useAppSelector((state) => state.matrix.axisMode)

  const changeCardStyle = (selected: SelectOption) => dispatch(setCardType(selected.text))

  const changePaletteStyle = (selected: PaletteSelection) => {
    if (selected.text === chosenPaletteStyle) return
    dispatch(updatePalette(selected.text))
  }

  const changeGridItemColor = (gridItem: GridItem, newColor: string) =>
    dispatch(updateGridItemColor({ gridItem, newColor }))

  const changeGridItemLabel = (gridItem: GridItem, newLabel: string) =>
    dispatch(updateGridItemLabel({ gridItem, newLabel }))

  const handleClose = () => {
    const initGridItems: GridItem[] = matrix.gridItems
    const initPaletteType: PaletteType = PaletteSchema.parse(matrix.paletteType)
    setErrors([])

    dispatch(setGridItems(initGridItems))
    dispatch(setPalette(initPaletteType))
    onClose()
  }

  const handleSave = async () => {
    if (labelErrors) {
      setErrors(["label_required".t("succession_plan")])
      return
    }
    setErrors([])

    const settings: UpdateCompanySuccessionMatrixSettingsInputObject = {
      cardType: chosenCardStyle,
      paletteType: chosenPaletteStyle,
      axisMode: chosenAxisMode,
      gridItems,
    }

    const result = await saveMatrixSettings({ input: { settings } }).unwrap()
    if (!result.updateCompanySuccessionMatrixSettings?.errors.length) {
      onClose()
    } else {
      const errors = (result.updateCompanySuccessionMatrixSettings?.errors || []).map(
        (s: Error) => s.message,
      )
      setErrors(errors)
    }
  }

  const dropdownPaletteOptions =
    chosenPaletteStyle === "palette_custom"
      ? [...viewablePaletteOptions, chosenPaletteStyle]
      : viewablePaletteOptions

  const invertAxisMode = () => (chosenAxisMode === "performance" ? "potential" : "performance")
  const labelForAxisMode = (independent: boolean) =>
    independent ? chosenAxisMode.t("succession_plan") : invertAxisMode().t("succession_plan")

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      overlayClassName="z-above-modal-modal"
      size="xl"
      title={"matrix_settings".t("succession_plan")}
    >
      <div className="matrix-settings z-above-modal-modal p-6">
        <AnimatePresence>
          {errors.length > 0 && (
            <motion.div
              key="error-banner"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: {
                  opacity: 1,
                  height: "auto",
                  transition: { duration: 0.2, opacity: { delay: 0.1 } },
                },
                collapsed: {
                  opacity: 0,
                  height: 0,
                  transition: { duration: 0.2, height: { delay: 0.1 } },
                },
              }}
            >
              <InputErrorBanner errors={errors} />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="mb-2 grid-cols-2 items-center gap-2 grid">
          <div className="mt-4 items-center space-x-4 flex">
            <div className="mb-4 place-content-center grid">
              <p className="mb-2 text-left font-bold text-neutral-100">X-Axis</p>
              <div className="border--main min-w-[108px] rounded-lg bg-neutral-3 p-[0.6rem]">
                <p className="w-full text-left text-neutral-64">{labelForAxisMode(true)}</p>
              </div>
            </div>
            <button
              type="button"
              className="btn--large btn--secondary mt-3 max-h-[39px] max-w-[40px]"
              onClick={() => dispatch(setAxisMode(invertAxisMode()))}
            >
              <FontAwesomeIcon
                className="ml-1 mt-[0.3rem] cursor-pointer"
                icon={prepareIconClass("fac arrow-left-right")}
                style={{ width: "20px", height: "20px" }}
              />
            </button>
            <div className="mb-4 grid">
              <p className="mb-2 text-left font-bold text-neutral-100">Y-Axis</p>
              <div className="border--main min-w-[108px] rounded-lg bg-neutral-3 p-[0.6rem]">
                <p className="w-full text-left text-neutral-64">{labelForAxisMode(false)}</p>
              </div>
            </div>
          </div>
          <div className="matrix-settings__fields flex">
            <div className="field grid">
              <p className="mb-2 font-bold text-neutral-100">Color Style</p>
              <SelectDropdown
                options={generateCardTypeOptions(cardOptions)}
                initialOption={generateCardTypeOption(chosenCardStyle)}
                onSelection={changeCardStyle}
              />
            </div>
            {chosenCardStyle !== "color_none" && (
              <div className="field w-full grid">
                <p className="mb-2 font-bold text-neutral-100">Color Scheme</p>
                <SelectDropdown
                  options={generatePaletteOptions(dropdownPaletteOptions, gridItems)}
                  initialOption={generatePaletteOption(chosenPaletteStyle, gridItems)}
                  onSelection={changePaletteStyle}
                />
              </div>
            )}
          </div>
        </div>
        <div className="matrix">
          <div className="matrix__left-bar">
            <div className="matrix__left-bar-text matrix-bar-text">{labelForAxisMode(false)}</div>
          </div>
          {gridItems.map((box) => (
            <MatrixGridItem
              key={box.id}
              item={box}
              cardStyle={chosenCardStyle}
              onUpdateItemLabel={changeGridItemLabel}
              onUpdateItemColor={changeGridItemColor}
            />
          ))}
          <div className="matrix__bottom-bar">
            <div className="matrix-bar-text">{labelForAxisMode(true)}</div>
          </div>
        </div>
      </div>
      <SaveFooter isSaving={isLoading} onSave={handleSave} onCancel={handleClose} />
    </Modal>
  )
}

export { SuccessionMatrixModal }

const generateMiniMatrix = (palette: string[], text: string, isField: boolean) => (
  <div className="items-center flex">
    <div className="mr-2 h-6 w-8 grid-cols-3 gap-[1px] grid">
      {palette.map((color, index) => (
        <div
          className="rounded-sm bg-neutral-8"
          style={{ backgroundColor: color }}
          // eslint-disable-next-line react/no-array-index-key
          key={`${color}-${index}`}
        />
      ))}
    </div>
    <span className={isField ? "font-bold" : ""}>{text.t("succession_plan")}</span>
  </div>
)

const generatePaletteOption = (option: PaletteType, gridItems: GridItem[]): PaletteSelection => {
  const palette = basePalettes[option].length
    ? basePalettes[option]
    : gridItems.map((item) => item.color)

  return {
    palette,
    text: option,
    fieldDisplay: generateMiniMatrix(palette, option, true),
    headerDisplay: generateMiniMatrix(palette, option, false),
  }
}

const generatePaletteOptions = (
  options: PaletteType[],
  gridItems: GridItem[],
): PaletteSelection[] => options.map((option) => generatePaletteOption(option, gridItems))

const generateMiniCard = (option: string) => (
  <div className="items-center flex">
    <div className={`matrix-card-type matrix-card-type__${option} mr-2`} />
    <p>{option.t("succession_plan")}</p>
  </div>
)

const generateCardTypeOption = (option: CardType) => ({
  text: option,
  fieldDisplay: generateMiniCard(option),
  headerDisplay: generateMiniCard(option),
})

const generateCardTypeOptions = (options: CardType[]) => options.map(generateCardTypeOption)
