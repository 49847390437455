import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { determineReportsTo } from "v2/react/components/orgChart/ProfilePanel/PositionHeader"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { UrlHelper } from "v2/react/utils/urls"
import { useAppSelector } from "v2/redux/store"

interface PositionInfoProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function PositionInfo({ abilities, featureFlags }: PositionInfoProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const position = useAppSelector((state) => state.profilePanel.position)
  const { currentBox, toggleBox } = useSelectedBox("position_info", ProfilePanelMode.Position)
  const { t } = useTranslation()

  const canReadRestrictedPositionFields = abilities.canReadRestrictedPositionFields

  if (!position || !currentBox) return null

  const titleDisplay = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.title")}</div>
      {!abilities.canManagePositionType ? (
        <a href={UrlHelper.positionTypePath(position.positionType?.id || "")}>
          {position.title || t("v2.profile_panel.untitled_position")}
        </a>
      ) : (
        <span>{position.title || t("v2.profile_panel.untitled_position")}</span>
      )}
    </ModuleListItem>
  )

  const positionIdDisplay = () => {
    if (canReadRestrictedPositionFields) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.position_id")}</div>
          <div>{position.systemIdentifier}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const reportsToDisplay = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.reports_to")}</div>
      <div>{determineReportsTo(position, t)}</div>
    </ModuleListItem>
  )

  const alsoFilledBy = () => {
    if (abilities.canReadRestrictedPositionFields && position.people?.length) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.also_filled_by")}</div>
          <div className="max-width-half">
            {position.people
              ?.filter((p) => p.id !== person?.id)
              .map((p) => p.name)
              .join(", ")}
          </div>
        </ModuleListItem>
      )
    }
    return null
  }

  const jobCodeDisplay = () => {
    if (canReadRestrictedPositionFields) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.job_code")}</div>
          <div>{position.jobCode}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const externalIdDisplay = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.external_id")}</div>
      <div>{position.externalIdentifier}</div>
    </ModuleListItem>
  )

  const importanceDisplay = () => {
    if (
      canReadRestrictedPositionFields &&
      (featureFlags.successionPlanning || featureFlags.positionManagement)
    ) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.importance")}</div>
          <div>
            {position.importance
              ? position.importance[0].toUpperCase() + position.importance.substring(1)
              : ""}
          </div>
        </ModuleListItem>
      )
    }
    return null
  }

  const successionPlanStatus = () => {
    if (canReadRestrictedPositionFields && featureFlags.successionPlanning) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.succession_plan")}</div>
          <div>
            {position.successionPlanStatus
              ? `succession_plan_${position.successionPlanStatus}`.t("succession_plan", "statuses")
              : ""}
          </div>
        </ModuleListItem>
      )
    }
    return null
  }

  const chartSectionDisplay = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.chart_section")}</div>
      <div>{position.chartSection}</div>
    </ModuleListItem>
  )

  const companyCodeDisplay = () => {
    if (canReadRestrictedPositionFields) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.company_code")}</div>
          <div>{position.companyCode}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const orgUnits = (position.orgUnits || []).map((unit) => (
    <ModuleListItem key={unit.field_id}>
      <div>{unit.orgUnitType?.name}</div>
      <div>
        {unit.formatted_value && unit.code
          ? `${unit.code} - ${unit.formatted_value}`
          : unit.formatted_value || unit.code}
      </div>
    </ModuleListItem>
  ))

  const jobLevel =
    featureFlags.positionManagement && canReadRestrictedPositionFields ? (
      <ModuleListItem>
        <div>{t("v2.profile_panel.job_level")}</div>
        <div>{position.positionType?.jobLevel?.codeNameLabel}</div>
      </ModuleListItem>
    ) : null

  const location = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.location")}</div>
      <div>{position.location?.label}</div>
    </ModuleListItem>
  )

  const officeRoomNumber = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.office_room_number")}</div>
      <div>{position.officeRoomNumber}</div>
    </ModuleListItem>
  )

  const fullTimeEquivalent = () => {
    if (canReadRestrictedPositionFields) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.fte")}</div>
          <div>{position.fte?.toFixed(1)}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const eeocClassification = () => {
    if (canReadRestrictedPositionFields && featureFlags.positionManagement) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.eeoc_classification")}</div>
          <div>
            {position.eeocClassification
              ? position.eeocClassification.t("org_chart", "eeoc_classifications")
              : ""}
          </div>
        </ModuleListItem>
      )
    }
    return null
  }

  const flsaClassification = () => {
    if (canReadRestrictedPositionFields && featureFlags.positionManagement) {
      return (
        <ModuleListItem>
          <div>{t("v2.profile_panel.flsa_classification")}</div>
          <div>{position.flsa?.name}</div>
        </ModuleListItem>
      )
    }
    return null
  }

  const headerContent = (
    <div className="w-full items-center justify-between flex">
      <div className="title">{t("v2.profile_panel.position_info")}</div>
    </div>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {positionIdDisplay()}
      {titleDisplay}
      {reportsToDisplay}
      {alsoFilledBy()}
      {jobCodeDisplay()}
      {externalIdDisplay}
      {importanceDisplay()}
      {successionPlanStatus()}
      {chartSectionDisplay}
      {companyCodeDisplay()}
      {orgUnits}
      {jobLevel}
      {location}
      {officeRoomNumber}
      {fullTimeEquivalent()}
      {eeocClassification()}
      {flsaClassification()}
    </AccordionModulePanel>
  )
}

export { PositionInfo }
