import type {
  SuccessionTableSettingsQuery,
  SuccessionTableSettingsQueryVariables,
  UpdatePersonSuccessionTableSettingsInput,
  UpdatePersonSuccessionTableSettingsMutation,
} from "types/graphql"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { queryOperation } from "v2/redux/utils/endpoints"

export const SuccessionApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getSuccessionTableSettings: builder.query<
      SuccessionTableSettingsQuery,
      SuccessionTableSettingsQueryVariables
    >({
      query: queryOperation<SuccessionTableSettingsQueryVariables>("SuccessionTableSettings"),
      providesTags: ["SuccessionTableSettings"],
    }),
    updatePersonSuccessionTableSettings: builder.mutation<
      UpdatePersonSuccessionTableSettingsMutation,
      UpdatePersonSuccessionTableSettingsInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdatePersonSuccessionTableSettings"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionTableSettings"],
    }),
  }),
})

export const {
  useGetSuccessionTableSettingsQuery,
  useUpdatePersonSuccessionTableSettingsMutation,
} = SuccessionApi
