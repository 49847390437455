import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"
import { pjaxReload } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"

import { Tabs } from "./SuccessionPlanPanel/Tabs"

interface Props {
  positionId: string | null
  hidePanel: () => void
}

function SuccessionPlanPanel({ positionId, hidePanel }: Props) {
  const { t } = useTranslation()
  const pjaxContainerRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!positionId) return

    const selector = "#succession-plan-panel-pjax-container"
    // NOTE: The current expected behavior is that when a pjax modal is loaded
    // on top of another existing pjax modal, the lower modal will be replaced.
    // This behavior happens on the Succession Plan Insights page, where the
    // compare settings close the insight details modal. Note that the react
    // matrix setting persist on top of the lower modal (a nicer UX for the
    // multiple modals case).

    pjaxReload({
      url: UrlHelper.successionPlanPanelPath(positionId),
      container: selector,
    })
  }, [positionId])

  return (
    <motion.div
      className="px-[1.1rem] py-6"
      data-position-id={positionId}
      variants={opaqueBasic}
      initial={opaqueBasic.hidden}
      animate={opaqueBasic.shown}
      exit={opaqueBasic.hidden}
    >
      <div className="-mb-1 justify-between flex">
        <h3 className="font-light uppercase text-neutral-64">
          {t("v2.positions.show.succession_plan")}
        </h3>
        <button
          type="button"
          className="close -mt-3 bg-transparent p-0 text-xl"
          onClick={hidePanel}
        >
          <FontAwesomeIcon icon={["far", "times"]} className="my-1" />
        </button>
      </div>
      <Tabs pjaxContainerRef={pjaxContainerRef} positionId={positionId || ""} />
      <div id="succession-plan-panel-pjax-container" ref={pjaxContainerRef} />
    </motion.div>
  )
}

export { SuccessionPlanPanel }
