/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"

import { JobRequisition } from "types/graphql"
import { ModalForm } from "v2/react/components/jobRequisitions/RequisitionForm/ModalForm"
import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { initialValuesForSchema } from "v2/react/components/jobRequisitions/RequisitionForm/utils/initialValuesForSchema"
import { useModalOverlayRef } from "v2/react/shared/overlay/Modal"
import { useGetJsonFormQuery } from "v2/redux/GraphqlApi"
import { setReqModalOpen } from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  data: FormShape
  edit: boolean
  jobRequisition: JobRequisition
  onClose?: () => void
  uiSchema?: any
  dataSchema?: any
  uiTableSchema?: any
}

function RequisitionModal({
  data,
  uiSchema,
  dataSchema,
  uiTableSchema,
  edit,
  jobRequisition,
  onClose,
}: Props) {
  const { modalRef, scrollToTop } = useModalOverlayRef()
  const { data: jsonForm } = useGetJsonFormQuery({ modelType: "JobRequisition" })
  const reqModalOpen = useAppSelector((state) => state.requisition.reqModalOpen)
  const dispatch = useAppDispatch()

  const closeModal = () => {
    dispatch(setReqModalOpen(false))
    if (onClose) onClose()
  }

  if (!jsonForm) return null
  const defaultData: FormShape = initialValuesForSchema(jsonForm.jsonForm)

  const finalJsonForm = {
    ...jsonForm.jsonForm,
    schema: {
      ...jsonForm.jsonForm.schema,
      ui_schema: uiSchema || jsonForm.jsonForm.schema.ui_schema,
      data_schema: dataSchema || jsonForm.jsonForm.schema.data_schema,
      ui_table_schema: uiTableSchema || jsonForm.jsonForm.schema.ui_table_schema,
    },
  }

  return (
    reqModalOpen && (
      <ModalForm
        closeModal={closeModal}
        edit={edit}
        initialData={data || defaultData}
        jobRequisition={jobRequisition}
        jsonForm={finalJsonForm}
        modalOverlayRef={modalRef}
        scrollToTop={scrollToTop}
      />
    )
  )
}

export { RequisitionModal }
