import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, PositionNestedJobRequisition } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { SendToAts } from "v2/react/components/jobRequisitions/SendToAts/SendToAts"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { InternalReqInfo } from "v2/react/components/orgChart/ProfilePanel/PositionModules/InternalReqInfo"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface RecruitingProps {
  featureFlags: FeatureFlags
}

function Recruiting({ featureFlags }: RecruitingProps) {
  const { currentBox, toggleBox } = useSelectedBox("recruiting", ProfilePanelMode.Position)
  const position = useAppSelector((state) => state.profilePanel.position)
  const { t } = useTranslation()

  if (!currentBox || !position) return null
  if (!featureFlags.positionManagement) return null

  const headerContent = <div className="title">{t("v2.profile_panel.recruiting")}</div>
  const requisitions = position.activeRecruitingJobRequisitions || []
  const internalRequisitions = requisitions.filter(
    (val: PositionNestedJobRequisition) => val.approvalState && !val.clientIdentifier,
  )

  const sendToAts = (
    <SendToAts
      asDropdownMenuLink={false}
      title={position.title || ""}
      positionId={position.id}
      locationName={position.location?.label || ""}
      orgUnitNames={(position.orgUnits || []).map((ou) => ou.name || "")}
    />
  )

  const internalInfoPanel = (
    <div>
      <InternalReqInfo requisitions={internalRequisitions} />
    </div>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div className="w-full">
          {!hasSendToAtsSupport() && internalRequisitions.length > 0 ? internalInfoPanel : ""}
          {hasSendToAtsSupport() ? sendToAts : ""}
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

const hasSendToAtsSupport = () =>
  // @ts-ignore
  window.gon.has_send_to_ats_support && window.gon.selected_chart?.official === true

export { Recruiting }
