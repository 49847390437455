import cn from "classnames"
import React, { SyntheticEvent } from "react"

import { ProgressBar } from "v2/react/shared/loaders/ProgressBar"

function Submit({
  onClick,
  isSubmitting,
}: {
  onClick: (e: SyntheticEvent) => void
  isSubmitting: boolean
}) {
  return (
    <div className="send-to-ats__modal-form-footer">
      <button
        type="submit"
        onClick={onClick}
        className={cn("btn--large btn--primary", { "btn-disabled": isSubmitting })}
      >
        {isSubmitting ? "submitting".t("ats") : "send_to_ats".t("ats")}
      </button>
      {isSubmitting && <ProgressBar loading={isSubmitting} />}
    </div>
  )
}

export { Submit }
