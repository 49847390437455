import {
  ProfilePanelSettingsQuery,
  ProfilePanelSettingsQueryVariables,
  UpdateProfilePanelBoxSettingsInput,
  UpdateProfilePanelBoxSettingsMutation,
  UpdateProfilePanelModeSettingInput,
  UpdateProfilePanelModeSettingMutation,
} from "types/graphql.d"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { queryOperation } from "v2/redux/utils/endpoints"

export const PeopleApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfilePlanSettings: builder.query<
      ProfilePanelSettingsQuery,
      ProfilePanelSettingsQueryVariables
    >({
      query: queryOperation<ProfilePanelSettingsQueryVariables>("ProfilePanelSettings"),
      providesTags: ["ProfilePanelSettings"],
    }),
    UpdateProfilePlanBoxSettings: builder.mutation<
      UpdateProfilePanelBoxSettingsMutation,
      UpdateProfilePanelBoxSettingsInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdateProfilePanelBoxSettings"),
        variables: { input },
      }),
      invalidatesTags: ["ProfilePanelSettings"],
    }),
    UpdateProfilePlanModeSetting: builder.mutation<
      UpdateProfilePanelModeSettingMutation,
      UpdateProfilePanelModeSettingInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdateProfilePanelModeSetting"),
        variables: { input },
      }),
      invalidatesTags: ["ProfilePanelSettings"],
    }),
  }),
})

export const {
  useGetProfilePlanSettingsQuery,
  useUpdateProfilePlanBoxSettingsMutation,
  useUpdateProfilePlanModeSettingMutation,
} = PeopleApi
