import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ControlProps } from "@jsonforms/core"
import classNames from "classnames"
import React, { ChangeEvent, FC, useState } from "react"

import { isDisabledFromSchema } from "v2/react/shared/jsonForms/JsonFormControls/utils/forms"
import { prepareIconClass } from "v2/react/utils/misc"

import { useValidation } from "./hooks/useValidation"
import { InputErrorText } from "./InputErrorText"
import { formatForJsonSchemaErrors } from "./utils/errors"

type CurrencyControlProps = Pick<
  ControlProps,
  "config" | "data" | "enabled" | "handleChange" | "id" | "label" | "path" | "schema"
>

const JsonCurrencyInput: FC<CurrencyControlProps> = ({
  config,
  data,
  enabled,
  handleChange,
  id,
  label,
  path,
  schema,
}: CurrencyControlProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const iconClass = window.gon.currency_icon_class || "far fa-dollar-sign"
  const isDisabled = isDisabledFromSchema({ enabled, schema })

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  const { showError, errorMessage } = useValidation({
    data: window.App.Helpers.parseCurrency(data || "") || null,
    path,
    schema,
    submitting: config.submitting,
  })

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    // NOTE: If not parsed, the value will fail any numeric validation as it
    // comes in as a string and is defined in the schema as a number. If one is
    // to use the CurrencyControl, the data schema should be defined as a
    // number (or this should be adjusted to handle non-numeric types in
    // future iterations).
    // Assumption, zero is not an acceptable value. (We set it to null to
    // trigger the blank validation error, otherwise a pre-parse blank check
    // should be added).
    handleChange(path, window.App.Helpers.parseCurrency(event.target.value) || null)
  }

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      {label && (
        <label
          htmlFor={id}
          className="cursor-default"
          onMouseEnter={handleFocus}
          onMouseLeave={handleBlur}
        >
          {label}
        </label>
      )}
      <div
        className={classNames("relative", {
          active: isFocused,
          readonly: isDisabled,
        })}
      >
        <div className="prefix">
          <FontAwesomeIcon icon={prepareIconClass(iconClass)} />
        </div>
        <input
          type="text"
          className="input prefix-pad"
          id={id}
          name={id}
          value={data || ""}
          onChange={handleChangeEvent}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={isDisabled}
        />
      </div>
      <InputErrorText message={formatForJsonSchemaErrors(errorMessage)} showError={showError} />
    </div>
  )
}

export { JsonCurrencyInput }
