import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"

export const handleEditPositionModalOpen = (
  positionId: string,
  pjaxReloadContainer?: string,
  pjaxReloadUrl?: string,
) => {
  const { jQuery: $, Modal } = window

  pjaxModalFor(
    UrlHelper.editPositionPath(positionId, editModalParams(pjaxReloadContainer, pjaxReloadUrl)),
  )

  if (!$ || !Modal) return

  $.onmount(".modal-overlay", () => {
    const modalRoot = window.$(".modal-overlay")
    if (modalRoot.find(".edit-position-modal").length < 1) return
    modalRoot.addClass("z-above-modal-modal")
  })
}

const editModalParams = (pjaxReloadContainer?: string, pjaxReloadUrl?: string) => {
  if (!pjaxReloadContainer || !pjaxReloadUrl) return ""

  // See: app/views/v2/positions/edit.html.slim
  return `?modal_reload=${pjaxReloadContainer}&modal_reload_url=${pjaxReloadUrl}`
}
