import classNames from "classnames"
import { Reorder, useMotionValue, useMotionValueEvent } from "framer-motion"
import React, { useState } from "react"

interface Props<ItemType> {
  item: ItemType
  itemClasses?: string
  dragContainerRef: React.RefObject<HTMLDivElement>
  children: React.ReactNode
}

export function DraggableListItem<ItemType>({
  item,
  itemClasses,
  dragContainerRef,
  children,
}: Props<ItemType>) {
  const [isDragging, setIsDragging] = useState(false)

  const y = useMotionValue(0)

  // If the drag is canceled by clicking on it after it's been released,
  // this resets the y value so it returns it the correct position.
  useMotionValueEvent(y, "animationCancel", () => y.set(0))

  return (
    <Reorder.Item
      as="div"
      value={item}
      className={classNames("list-group-item", itemClasses, { dragging: isDragging })}
      dragConstraints={dragContainerRef}
      dragElastic={0}
      dragTransition={{
        bounceStiffness: 200,
        bounceDamping: 20,
        modifyTarget: () => 0, // Ensures item always goes to the correct position
      }}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      whileDrag={{ boxShadow: "0 0.125rem 0.25rem 0 rgba(0,0,0,0.20)" }}
      style={{ y }}
    >
      {children}
    </Reorder.Item>
  )
}
