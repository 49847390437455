import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { JobRequisition } from "types/graphql"
import { RequisitionModal } from "v2/react/components/jobRequisitions/RequisitionModal"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import RootProvider from "v2/react/components/RootProvider"
import { setReqModalOpen } from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch } from "v2/redux/store"

import { FormShape } from "./RequisitionForm/types/FormShape"

interface Props {
  abilities: JobRequisitionAbilities
  data: FormShape
  jobRequisition: JobRequisition
}

function RequisitionFormInner({ abilities, data, jobRequisition }: Props) {
  const dispatch = useAppDispatch()
  const handleAddButtonClick = () => dispatch(setReqModalOpen(true))

  return (
    <>
      {abilities.canCreate && (
        <button className="btn--large btn--primary" onClick={handleAddButtonClick} type="button">
          <FontAwesomeIcon icon={["far", "plus"]} />
          <span className="hidden sm:block">{"create_requisition".t("job_requisition")}</span>
        </button>
      )}
      <RequisitionModal data={data} edit={false} jobRequisition={jobRequisition} />
    </>
  )
}

const RequisitionForm = ({ abilities, data, jobRequisition }: Props) => (
  <RootProvider>
    <RequisitionFormInner abilities={abilities} data={data} jobRequisition={jobRequisition} />
  </RootProvider>
)

export { RequisitionForm }
