import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { Person, PositionType } from "types/graphql"
import { Avatar } from "v2/react/shared/Avatar"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { UrlHelper } from "v2/react/utils/urls"
import { useLazyGetPositionTypePeopleInterestedQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

const PAGE_SIZE = 10

interface Props {
  positionType: PositionType
}

function PeopleInterestedList({ positionType }: Props) {
  const [allPeople, setAllPeople] = React.useState<Person[]>([])
  const { t } = useTranslation()
  const scrollBoxRef = useRef<null | HTMLDivElement>(null)

  // https://stackoverflow.com/questions/62587993/how-to-render-usequery-both-on-first-render-and-onclick-reactjs-graphql
  const [queryPeopleTrigger, { data, isLoading }] = useLazyGetPositionTypePeopleInterestedQuery()

  useEffect(() => {
    queryPeopleTrigger({
      id: `position_type_${positionType.id}`,
      first: PAGE_SIZE,
    })
  }, [positionType.id, queryPeopleTrigger])

  useEffect(() => {
    setAllPeople((prevValue) => [
      ...prevValue,
      ...(data?.positionType?.interestedPeople?.nodes || []),
    ])
  }, [data])

  useEffect(() => {
    // If subsequent pages of people are loaded, scroll to the bottom of the
    // list to show the new people.
    if (scrollBoxRef.current && allPeople.length > PAGE_SIZE) {
      // See: https://stackoverflow.com/a/65371198
      scrollBoxRef.current.scroll({ top: scrollBoxRef.current.scrollHeight, behavior: "smooth" })
    }
  }, [allPeople, scrollBoxRef])

  if (isLoading || !data?.positionType?.totalInterestedPeopleCount) return null

  const endCursor = data.positionType.interestedPeople?.pageInfo?.endCursor
  const nextPageExists = data.positionType.interestedPeople?.pageInfo?.hasNextPage

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">
          {t("v2.position_types.show.people_interested_in_this_job")}
        </div>
      </div>
      <div
        className="module-card__body max-h-[400px] gap-0 overflow-y-auto border-0 border-solid border-neutral-8 p-0"
        ref={scrollBoxRef}
      >
        {(allPeople || []).map((person: Person) => (
          <PersonRow person={person} key={person.id} />
        ))}
      </div>
      {!isLoading && nextPageExists && (
        <div className="justify-center border-0 border-t border-solid border-neutral-8 p-4 flex">
          <button
            className="bg-white text-neutral-64"
            type="button"
            onClick={() => {
              queryPeopleTrigger({
                id: `position_type_${positionType.id}`,
                first: PAGE_SIZE,
                after: endCursor,
              })
            }}
          >
            <span>{t("v2.position_types.show.view_more", { count: PAGE_SIZE })}</span>
            <span className="pl-2">
              <FontAwesomeIcon icon={["far", "chevron-down"]} />
            </span>
          </button>
        </div>
      )}
      {isLoading && (
        <div className="p-4">
          <Spinner style={{ position: "relative", height: "1rem", width: "1rem" }} />
        </div>
      )}
    </div>
  )
}

export { PeopleInterestedList }

const PersonRow = ({ person }: { person: Person }) => (
  <a
    className="border-0 border-t border-solid border-neutral-8 px-4 py-4 text-base text-neutral-100 no-underline duration-200 ease-in-out flex hover:bg-neutral-3-solid hover:no-underline"
    href={UrlHelper.personProfilePath(person.id || "")}
  >
    <div className="items-center pr-2 flex">
      {person?.avatarThumbUrl ? (
        <Avatar person={person} size="med" />
      ) : (
        <div className="h-8 w-8 shrink-0 grow-0 items-center justify-center rounded-full bg-primary-8-solid text-primary-100 flex">
          <FontAwesomeIcon icon={["far", "chair-office"]} />
        </div>
      )}
    </div>
    <div className="flex-col justify-center flex">
      <p className="text-base">{person.name}</p>
      {person.primaryPosition?.title && (
        <p className="text-sm text-neutral-64">{person.primaryPosition.title}</p>
      )}
    </div>
  </a>
)
