/**
 * Given a path from a graphql Error, translate it to an AJV instance path.
 *
 * See: https://github.com/ajv-validator/ajv-errors
 *
 * Example
 *   > ajvInstancePath('jobTitle')
 *   > /position/jobTitle
 * @param {string} errorPath The Built Graphql Error's path
 * @returns {string} An "instance path," which for json form data is prefixed
 *   with a forward slash.
 */
const ajvInstancePath = (errorPath: string): string => {
  if (errorPath === "systemUid") return "/systemUid"
  if (errorPath === "jobTitle") return "/position/jobTitle"
  if (errorPath === "externalIdentifier") return "/position/externalIdentifier"
  return `/${errorPath}`
}

/**
 * Given a path from a graphql Error, translate it to an AJV schema path.
 *
 * See: https://github.com/ajv-validator/ajv-errors
 *
 * Example
 *   > ajvInstancePath('jobTitle')
 *   > #/properties/position/properties/jobTitle/errorMessage
 * @param {string} errorPath The Built Graphql Error's path
 * @returns {string} A "schema path," which for json form data is prefixed
 *   with a forward slash.
 */
const ajvSchemaPath = (errorPath: string): string => {
  if (errorPath === "systemUid") return "#/properties/systemUid/errorMessage"
  if (errorPath === "jobTitle") return `#/properties/position/properties/${errorPath}/errorMessage`
  if (errorPath === "externalIdentifier") {
    return `#/properties/position/properties/${errorPath}/errorMessage`
  }
  return `#/properties/${errorPath}/errorMessage`
}

export { ajvInstancePath, ajvSchemaPath }
