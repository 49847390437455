import classNames from "classnames"
import React, { useEffect, useState } from "react"

import { generateConditionalColors } from "v2/react/components/successionSettings/matrixUtils"
import { SuccessionMatrixModal } from "v2/react/components/successionSettings/SuccessionMatrixModal"
import { useGetSuccessionMatrixSettingsQuery } from "v2/redux/GraphqlApi"
import {
  AxisModeSchema,
  AxisModeType,
  CardType,
  CardTypeSchema,
  GridItem,
  GridItemSchema,
  PaletteSchema,
  setAxisMode,
  setCardType,
  setGridItems,
  setPalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch } from "v2/redux/store"
import { StoreProvider } from "v2/redux/StoreProvider"

function SuccessionMatrixSettingsInner() {
  const dispatch = useAppDispatch()
  const { data: matrix } = useGetSuccessionMatrixSettingsQuery({})
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  useEffect(() => {
    if (matrix) {
      dispatch(setCardType(CardTypeSchema.parse(matrix.cardType)))
      dispatch(setPalette(PaletteSchema.parse(matrix.paletteType)))
      dispatch(setGridItems(GridItemSchema.array().parse(matrix.gridItems)))
      dispatch(setAxisMode(AxisModeSchema.parse(matrix.axisMode)))
    }
  }, [matrix, dispatch])

  if (!matrix) return null

  const handleOpenModal = () => setEditModalIsOpen(true)
  const handleCloseModal = () => setEditModalIsOpen(false)

  const mediumMatrix = generateMediumMatrix(
    GridItemSchema.array().parse(matrix.gridItems),
    CardTypeSchema.parse(matrix.cardType),
    AxisModeSchema.parse(matrix.axisMode),
  )

  return (
    <>
      <div className="module-card">
        <div className="module-card__header items-center justify-between flex">
          <div className="text-base-bold">{"matrix_settings".t("succession_plan")}</div>
          <button className="btn--sm btn--secondary" onClick={handleOpenModal} type="button">
            {"Edit".t("defaults")}
          </button>
        </div>
        <div className="module-card__body pb-[1rem]">{mediumMatrix}</div>
      </div>
      <SuccessionMatrixModal isOpen={editModalIsOpen} onClose={handleCloseModal} matrix={matrix} />
    </>
  )
}

function SuccessionMatrixSettings() {
  return (
    <StoreProvider>
      <SuccessionMatrixSettingsInner />
    </StoreProvider>
  )
}

export default SuccessionMatrixSettings

const generateMediumMatrix = (
  gridItems: GridItem[],
  cardType: CardType,
  axisMode: AxisModeType,
) => {
  const labelForAxisMode = (independent: boolean) =>
    independent
      ? axisMode.t("succession_plan")
      : (axisMode === "performance" ? "potential" : "performance").t("succession_plan")

  return (
    <div className="matrix h-64 gap-[0.35rem]">
      <div className="matrix__left-bar bg-primary-8-solid">
        <div className="matrix__left-bar-text matrix-bar-text">{labelForAxisMode(false)}</div>
      </div>
      {gridItems.map((item, index) => (
        <div
          className="matrix-medium__grid-item"
          style={generateConditionalColors(cardType, item.color)}
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.color}-${index}`}
        >
          <div
            className={classNames("matrix-medium__grid-item-header text-base", {
              opaque: cardType !== "color_edge",
            })}
          >
            <p>{item.label}</p>
          </div>
        </div>
      ))}
      <div className="matrix__bottom-bar bg-primary-8-solid">
        <div className="matrix-bar-text">{labelForAxisMode(true)}</div>
      </div>
    </div>
  )
}
