import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { FeatureFlags, Maybe } from "types/graphql"

type SessionState = SessionActiveState | SessionInitializedState | GuestSessionState

enum SessionStatus {
  Guest = "guest",
  SessionInitialized = "initialized",
  SessionActive = "active",
}

const InitialState: SessionState = {
  status: SessionStatus.Guest,
  canWriteChangesToAdp: false,
  currentUserId: null,
  currentCompanyId: null,
  currentPersonId: null,
  currentPersonName: null,
  currentPersonTimezone: null,
  featureFlags: null,
  loadedI18n: false,
}

/** @public */
const SessionSlice = createSlice({
  name: "session",
  initialState: InitialState as SessionState,
  reducers: {
    authenticate: (state, { payload }: AuthenticateAction) => {
      if (payload.currentPersonId && payload.currentPersonName) {
        return fp.assign(state, {
          status: SessionStatus.SessionActive,
          canWriteChangesToAdp: !!payload.canWriteChangesToAdp,
          currentCompanyId: payload.currentCompanyId,
          currentUserId: payload.currentUserId,
          currentPersonId: payload.currentPersonId,
          currentPersonFullName: payload.currentPersonName,
          currentPersonTimezone: payload.currentPersonTimezone,
        })
      }

      return fp.assign(state, {
        status: SessionStatus.SessionInitialized,
        currentUserId: payload.currentUserId,
      })
    },

    choosePerson: (state, { payload }: ChoosePersonAction) => {
      if (state.status === SessionStatus.Guest) return state

      return fp.assign(state, {
        status: SessionStatus.SessionActive,
        canWriteChangesToAdp: !!payload.canWriteChangesToAdp,
        currentCompanyId: payload.currentCompanyId,
        currentPersonId: payload.currentPersonId,
        currentPersonFullName: payload.currentPersonName,
        currentPersonTimezone: payload.currentPersonTimezone,
      })
    },

    loadedI18n: (state, payload: PayloadAction<boolean>) => fp.set("loadedI18n", payload, state),
  },
})

type Id = number | string

type GuestSessionState = {
  status: SessionStatus.Guest
  canWriteChangesToAdp: false
  currentCompanyId: null
  currentUserId: null
  currentPersonId: null
  currentPersonName: null
  currentPersonTimezone: null
  loadedI18n: boolean
  featureFlags: null
}

type SessionInitializedState = {
  status: SessionStatus.SessionInitialized
  canWriteChangesToAdp: boolean
  currentCompanyId: null
  currentUserId: Id
  currentPersonId: null
  currentPersonName: null
  currentPersonTimezone: null
  loadedI18n: boolean
  featureFlags: null
}

type SessionActiveState = {
  status: SessionStatus.SessionActive
  canWriteChangesToAdp: boolean
  currentCompanyId: Id
  currentUserId: Id
  currentPersonId: Id
  currentPersonName: string
  currentPersonTimezone: string
  loadedI18n: boolean
  featureFlags: FeatureFlags
}

type AuthenticateAction = PayloadAction<{
  canWriteChangesToAdp?: boolean
  currentUserId: Id
  currentCompanyId?: Maybe<Id>
  currentPersonId?: Maybe<Id>
  currentPersonName?: Maybe<string>
  currentPersonTimezone?: Maybe<string>
}>

type ChoosePersonAction = PayloadAction<{
  canWriteChangesToAdp?: boolean
  currentCompanyId: Id
  currentPersonId: Id
  currentPersonName: string
  currentPersonTimezone: string
}>

export const { authenticate, choosePerson, loadedI18n } = SessionSlice.actions
export { SessionSlice, SessionState, SessionStatus }
