import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, Position } from "types/graphql"
import { PositionsSubPageNav } from "v2/react/components/positions/PositionsSubPageNav"
import { Budget } from "v2/react/components/positions/Show/Budget"
import { Description } from "v2/react/components/positions/Show/Description"
import { Details } from "v2/react/components/positions/Show/Details"
import { FilledBy } from "v2/react/components/positions/Show/FilledBy"
import { PreviouslyFilledBy } from "v2/react/components/positions/Show/PreviouslyFilledBy"
import { Requirements } from "v2/react/components/positions/Show/Requirements"
import { Responsibilities } from "v2/react/components/positions/Show/Responsibilities"
import { SuccessionPlan } from "v2/react/components/positions/Show/SuccessionPlan"
import { Summary } from "v2/react/components/positions/Show/Summary"
import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetFeatureFlagsQuery, useGetPositionDetailsQuery } from "v2/redux/GraphqlApi"

interface Abilities {
  canEditCharacteristics: boolean
  canEditDescription: boolean
  canEditResponsibilities: boolean
  canEditSkills: boolean
  canEditSummary: boolean
  canManageCompensation: boolean
  canReadCompensation: boolean
}

interface ShowProps {
  abilities: Abilities
  positionId: number
}

function WithProvider({ abilities, positionId }: ShowProps) {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery()

  const { t } = useTranslation()
  const { data: positionResult, isLoading: positionIsLoading } = useGetPositionDetailsQuery({
    positionId: `position_${positionId}`,
  })
  const position: Position | null | undefined = positionResult?.position
  const featureFlags: FeatureFlags | undefined = featureFlagsData?.currentCompany?.featureFlags

  if (positionIsLoading || !position || !featureFlags) {
    return (
      <div className="relative h-screen">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <PositionsSubPageNav
        isOpen={!position.filledBy?.length}
        parentTitle={position.title || t("v2.positions.show.untitled_position")}
        parentTitleUrl={
          position.positionType?.id && UrlHelper.positionTypePath(position.positionType?.id)
        }
        title={`${t("v2.positions.show.position")} ${position.systemIdentifier}`}
      />
      <div className="page-pad">
        <div className="grid-cols-1 gap-3 grid sm:grid-cols-[2fr_1fr] sm:gap-8">
          <div>
            <div className="sm:hidden">
              <FilledBy position={position} />
            </div>
            <Summary
              position={position}
              canEdit={abilities.canEditSummary}
              featureFlags={featureFlags}
            />
            <Details position={position} featureFlags={featureFlags} />
            {featureFlags.positionManagement && abilities.canReadCompensation && (
              <Budget abilities={abilities} position={position} />
            )}
            <Requirements
              position={position}
              canEditCharacteristics={abilities.canEditCharacteristics}
              canEditSkills={abilities.canEditSkills}
              featureFlags={featureFlags}
            />
            <Responsibilities
              canEdit={abilities.canEditResponsibilities}
              position={position}
              featureFlags={featureFlags}
            />
            {featureFlags.positionManagement && (
              <Description canEdit={abilities.canEditDescription} position={position} />
            )}
          </div>
          <div>
            <div className="hidden sm:block">
              <FilledBy position={position} />
            </div>
            <SuccessionPlan
              abilities={{ canManageSuccessionPlan: abilities.canManageCompensation }}
              positionId={`position_${positionId}`}
            />
            {featureFlags.positionManagement &&
              position.previouslyFilledBy &&
              position.previouslyFilledBy.length > 0 && <PreviouslyFilledBy position={position} />}
          </div>
        </div>
      </div>
    </>
  )
}

const Show = ({ abilities, positionId }: ShowProps) => (
  <RootProvider>
    <WithProvider abilities={abilities} positionId={positionId} />
  </RootProvider>
)

export { Show }
