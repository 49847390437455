import { zodResolver } from "@hookform/resolvers/zod"
import { isNil } from "lodash"
import { head } from "lodash/fp"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as z from "zod"

import { Modal, useModalOverlayRef } from "v2/react/shared/overlay/Modal"
import { useSendToAtsMutation } from "v2/redux/GraphqlApi/JobRequisitionsApi"

import { DepartmentField } from "./fields/DepartmentField"
import { JobTitleField } from "./fields/JobTitleField"
import { LocationField } from "./fields/LocationField"
import { SystemUidField } from "./fields/SystemUidField"
import { FormError } from "./FormError"
import { Submit } from "./Submit"
import { InitFailure, isForCreation } from "./utils"

const LeverFormSchema = z.object({
  jobTitle: z.string().nonempty(),
  departmentName: z.string().nullish(),
  locationName: z.string().nullish(),
  jobRequisitionId: z.string().nullish(),
  systemUid: z.string().nullish(),
})

type LeverFormSchemaType = typeof LeverFormSchema
type LeverFormType = z.infer<typeof LeverFormSchema>

type LeverInitSuccess = {
  integration: "lever"
  success: true
  data: LeverFormType
  schema: LeverFormSchemaType
}

type InitLeverResponse = LeverInitSuccess | InitFailure

function initLeverForm(
  jobTitle: string,
  departments: string[],
  location: string,
): InitLeverResponse {
  return {
    integration: "lever",
    success: true,
    data: {
      systemUid: "",
      jobTitle,
      departmentName: departments.length > 0 ? departments[0] : undefined,
      locationName: location,
    } as LeverFormType,
    schema: LeverFormSchema,
  }
}

function mapDataToInput(
  jobRequisitionId: string,
  systemUid: string,
  positionId: string | null,
  data: LeverFormType,
) {
  const input = {
    ...data,
    departmentName: data.departmentName ? data.departmentName : undefined,
    locationName: data.locationName ? data.locationName : undefined,
    positionId,
  }
  if (!isForCreation(jobRequisitionId)) {
    input.jobRequisitionId = jobRequisitionId
    input.systemUid = systemUid
  }
  return { input }
}

interface LeverFormProps {
  closeModal: () => void
  formSchema: LeverFormSchemaType
  initialFormData: LeverFormType
  isOpen: boolean
  jobRequisitionId?: string
  positionId: string | null
  systemUid?: string
}

function LeverForm({
  closeModal,
  formSchema,
  initialFormData,
  isOpen,
  positionId,
  jobRequisitionId = "",
  systemUid = "",
}: LeverFormProps) {
  const [mutate] = useSendToAtsMutation()
  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: initialFormData,
    resolver: zodResolver(formSchema),
  })

  const { modalRef, scrollToTop } = useModalOverlayRef()

  const showError = (message: string) => {
    setError("root.serverError", { message })
    scrollToTop()
  }
  const rootErrorMsg = errors?.root?.serverError?.message

  const onSubmit: SubmitHandler<LeverFormType> = async (data) => {
    if (!isValid) {
      return
    }
    try {
      const mapped = mapDataToInput(jobRequisitionId, systemUid, positionId, data)

      const result = await mutate({ input: mapped.input }).unwrap()
      if (isNil(result)) {
        showError("unexpected_error".t("ats"))
        return
      }
      const error = head(result?.sendToAts?.errors)
      if (error) {
        switch (error.message) {
          case "input_error_team":
            showError("input_error_team".t("ats", null, null, null, [mapped.input.departmentName]))
            return
          case "input_error_location":
            showError(
              "input_error_location".t("ats", null, null, null, [mapped.input.locationName]),
            )
            return
          case "input_error_requisition_code":
            showError(
              "input_error_requisition_code".t("ats", null, null, null, [
                mapped.input.jobRequisitionId,
              ]),
            )
            return
          case "no_ats_integration":
            showError("no_ats_integration".t("ats"))
            return
          case "rate_limited_error":
            showError("try_again".t("ats"))
            return
          case "This Requisition ID has already been taken.":
            showError("req_id_taken".t("ats"))
            return
          default:
            showError("failed_to_send".t("ats"))
            return
        }
      }
      closeModal()
      window.location.reload()
    } catch (error) {
      const message = "unexpected_error".t("ats")
      setError("root.serverError", { message })
      scrollToTop()
    }
  }

  const reg = {
    systemUid: register("systemUid"),
    jobTitle: register("jobTitle"),
    departmentName: register("departmentName"),
    locationName: register("locationName"),
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      footer={<Submit onClick={handleSubmit(onSubmit)} isSubmitting={isSubmitting} />}
      title={"send_to_ats".t("ats")}
      overlayRef={modalRef}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="react-modal__body">
          <FormError message={rootErrorMsg} />
          <SystemUidField
            textInputRef={reg.systemUid.ref}
            name={reg.systemUid.name}
            onChange={reg.systemUid.onChange}
            defaultValue={initialFormData.systemUid || undefined}
            show={isForCreation(jobRequisitionId)}
          />
          <JobTitleField
            name={reg.jobTitle.name}
            textInputRef={reg.jobTitle.ref}
            onChange={reg.jobTitle.onChange}
            defaultValue={initialFormData.jobTitle}
            show={isForCreation(jobRequisitionId)}
            error={!!errors.jobTitle}
          />
          <DepartmentField
            name={reg.departmentName.name}
            textInputRef={reg.departmentName.ref}
            onChange={reg.departmentName.onChange}
            defaultValue={initialFormData.departmentName || ""}
          />
          <LocationField
            name={reg.locationName.name}
            textInputRef={reg.locationName.ref}
            onChange={reg.locationName.onChange}
            defaultValue={initialFormData.locationName || ""}
          />
        </div>
      </form>
    </Modal>
  )
}

export { initLeverForm, LeverForm, LeverInitSuccess }
