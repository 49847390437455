import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import currencyIcons from "app_config/currency_icons.json"
import classNames from "classnames"
import { isNil } from "lodash"
import React, { forwardRef, useState } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import { prepareIconClass } from "v2/react/utils/misc"

type IconClass = (typeof currencyIcons)[keyof typeof currencyIcons]

export interface CurrencyInputProps {
  id: string
  name: string
  defaultValue?: string | undefined | number
  value?: string | undefined | number
  disabled?: boolean
  errors?: string
  iconClass?: IconClass
  inputClassName?: string
  label?: string
  placeholder?: string
  readOnly?: boolean
  showErrorMessage?: boolean
  useAttentionState?: boolean
  wrapperClassName?: string
  wrapperCombineClassNames?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  renderErrors?: (errors: string) => React.ReactNode
}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      id,
      name,
      defaultValue,
      value,
      disabled = false,
      errors,
      iconClass = window?.gon?.currency_icon_class || "far fa-dollar-sign",
      inputClassName,
      label,
      placeholder = "",
      readOnly = false,
      showErrorMessage = true,
      useAttentionState = false,
      wrapperClassName,
      wrapperCombineClassNames,
      onChange,
      renderErrors,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const [inputValue, setInputValue] = useState<string>(
      isNil(defaultValue) ? "" : `${defaultValue}`,
    )

    const showAttention = useAttentionState && !isFocused && !errors && !inputValue.trim()

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
      onChange?.(e)
    }

    const inputProps = {
      ref,
      type: "text",
      className: classNames("input prefix-pad", inputClassName),
      id,
      name,
      placeholder,
      onChange: handleChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
      readOnly,
      disabled,
    }

    return (
      <InputWrapper
        label={label}
        errors={errors}
        showErrorMessage={showErrorMessage}
        renderErrors={renderErrors}
        id={id}
        className={wrapperClassName}
        combineClassNames={wrapperCombineClassNames}
        onMouseEnterLabel={handleFocus}
        onMouseLeaveLabel={handleBlur}
      >
        <div
          className={classNames("relative", {
            readonly: readOnly || disabled,
            active: isFocused,
            attention: showAttention,
          })}
        >
          <div className="prefix">
            <FontAwesomeIcon icon={prepareIconClass(iconClass)} />
          </div>
          {value === undefined ? (
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
              defaultValue={isNil(defaultValue) ? "" : `${defaultValue}`}
            />
          ) : (
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
              value={value}
            />
          )}
        </div>
      </InputWrapper>
    )
  },
)

export { CurrencyInput }
