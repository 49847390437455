import React from "react"

import { Collections, HeadcountPlan } from "types/graphql"
import { HeadcountPlanDatasheet } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet"
import { useHeadcountPlanDatasheet } from "v2/react/hooks/useHeadcountPlanDatasheet"

import { OwnerBanner } from "./OwnerBanner"

interface Props {
  csvDownloadRef: React.RefObject<HTMLButtonElement>
  displayState: "approved" | "original"
  dropdownCellCollections: Collections | undefined
  headcountPlan: HeadcountPlan | undefined
  headcountPlanId: string
  showGroupByDropzone?: boolean
}

export function OwnerDatasheet({
  csvDownloadRef,
  displayState,
  dropdownCellCollections,
  headcountPlan,
  headcountPlanId,
  showGroupByDropzone,
}: Props) {
  const allPositionsInPlan = headcountPlan?.allPositionsInPlan ?? []
  const approvedPositions = headcountPlan?.approvedPositions ?? []
  const isFinalized = !!headcountPlan?.lockedAt
  const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "")
  const csvDownloadName = `${headcountPlan?.name}_${currentDate}`.replace(/\s/g, "")

  const { rows, columns } = useHeadcountPlanDatasheet({
    attributes: headcountPlan?.allowedAttributes ?? [],
    positions: isFinalized && displayState === "approved" ? approvedPositions : allPositionsInPlan,
    dropdownCellCollections: dropdownCellCollections ?? {},
    hideOptions: isFinalized,
    showStatus: isFinalized && displayState === "original",
    newPositionIdPrefix: headcountPlan?.newPositionIdPrefix,
  })

  return (
    <>
      <OwnerBanner headcountPlan={headcountPlan} />
      <HeadcountPlanDatasheet
        forEntireOrg
        headcountPlanId={headcountPlanId}
        headcountPlan={headcountPlan}
        rows={rows}
        columns={columns}
        readOnly={isFinalized}
        csvDownloadRef={csvDownloadRef}
        csvDownloadName={csvDownloadName}
        showGroupByDropzone={showGroupByDropzone}
      />
    </>
  )
}
