import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { QueryPositionArgs } from "types/graphql"
import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { useAppSelector } from "v2/redux/store"

import { IS_V2 } from "../utils/constants"
import { RemovePositionModal } from "./RemovePositionModal"
import { handleEditPositionModalOpen } from "./utils/handleEditPositionModalOpen"
import { handleViewHistoryModalOpen } from "./utils/handleViewHistoryModalOpen"

interface PositionActionsProps {
  canDelete?: boolean
  canEdit?: boolean
  canViewHistory?: boolean
  canViewSystemIdentifier?: boolean
  containerKey?: string | null
  hasChildren: boolean
  isV2: boolean
  isHeadcountPlanner: boolean
  pjaxReloadContainer?: string
  pjaxReloadUrl?: string
  positionId: string
  positionQuery?: (params: QueryPositionArgs) => void
  systemIdentifier?: string | null
}

const PositionActions = ({
  canDelete,
  canEdit,
  canViewHistory,
  canViewSystemIdentifier,
  containerKey,
  hasChildren,
  isHeadcountPlanner,
  pjaxReloadContainer,
  pjaxReloadUrl,
  positionId,
  positionQuery,
  systemIdentifier,
  isV2 = true,
}: PositionActionsProps) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const isOfficialChart = useAppSelector((state) => state.profilePanel.isOfficialChart)

  useEffect(() => {
    if (IS_V2 && positionQuery) {
      const { jQuery: $ } = window
      $.onmount("[data-modal='standard']", () => {
        $("[data-modal='standard']").on("modal:save", () => {
          // This is a bit hacky. Here we fetch some global state from the DOM from
          // the Edit Position Modal. We do this to try and limit the onmount
          // handlers. This is one (very coupled) way to get the updated positionId
          // from the modal, but it has the advantage of not using timeouts and uses a
          // simple jQuery selector. Otherwise, it is difficult to get the updated
          // positionId without some shared state between the pjax modals and save
          // handlers and the component here.
          const updatedPositionId = $("[data-modal='standard'] #position_id_value").val()

          if (updatedPositionId) {
            positionQuery({ positionId: updatedPositionId || "" })
          }
        })
      })
    }
  }, [positionQuery])

  const handleDeletePosition = (positionId: string) => {
    if (isV2) {
      setShowModal(true)
      return
    }
    // The org chart delete position flow has some unique behavior that is not
    // currently supported on v2 pages that use the React component UI flow.
    window?.App?.OrgChart?.deleteById(positionId)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleEditOpen = () => {
    handleEditPositionModalOpen(positionId, pjaxReloadContainer, pjaxReloadUrl)
  }

  const handleViewHistory = () => {
    handleViewHistoryModalOpen({ containerKey, positionId })
  }

  return canEdit || canDelete || canViewHistory || canViewSystemIdentifier ? (
    <>
      <RemovePositionModal
        editingDisabled={!canEdit}
        hasChildren={hasChildren}
        isHeadcountPlanner={isHeadcountPlanner}
        isOfficialChart={isOfficialChart}
        isOpen={showModal}
        onClose={handleClose}
        pjaxReloadContainer={pjaxReloadContainer}
        pjaxReloadUrl={pjaxReloadUrl}
        positionId={positionId}
      />
      <Dropdown align="right">
        <Dropdown.Trigger triggerClassName="btn--icon btn--ghost position-actions">
          <FontAwesomeIcon icon={["far", "ellipsis-h"]} />
        </Dropdown.Trigger>
        <Dropdown.Menu className="!z-above-modal-dropdown">
          {canEdit && (
            <Dropdown.Item
              onClick={handleEditOpen}
              withIcon={<FontAwesomeIcon icon={["far", "pen"]} />}
            >
              {t("v2.profile_panel.edit_position")}
            </Dropdown.Item>
          )}
          {canViewSystemIdentifier && systemIdentifier && (
            <Dropdown.Item.CopyInline
              label={t("v2.profile_panel.copy_position_id")}
              valueToCopy={systemIdentifier}
            />
          )}
          {canViewHistory && (
            <Dropdown.Item
              onClick={handleViewHistory}
              withIcon={<FontAwesomeIcon icon={["far", "history"]} />}
            >
              {t("v2.profile_panel.view_history")}
            </Dropdown.Item>
          )}
          {canDelete && (
            <>
              <hr className="my-2" />

              <Dropdown.Item
                onClick={() => handleDeletePosition(positionId)}
                withIcon={
                  <FontAwesomeIcon icon={["far", "trash-alt"]} className="!text-status-critical" />
                }
              >
                <span className="text-status-critical">
                  {t("v2.profile_panel.remove_position")}
                </span>
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  ) : null
}

export { PositionActions }
