import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const InputErrorText = ({ message }: { message: string }) => (
  <div className="mt-1 items-center gap-1 text-status-critical flex">
    <FontAwesomeIcon icon={["far", "exclamation-circle"]} className="text-inherit" />
    <span>{message}</span>
  </div>
)

export { InputErrorText }
