import React from "react"

import { Recruiter } from "types/graphql"
import { PersonName } from "v2/react/components/approvals/ApproverList/PersonName"
import { PersonTitle } from "v2/react/components/approvals/ApproverList/PersonTitle"
import { Avatar } from "v2/react/shared/Avatar"
import { itemSuffix } from "v2/react/shared/collection/OrderedList/OrderListItem"
import { personNameAsc } from "v2/react/utils/sorts"

interface RecruiterListProps {
  recruiters: Recruiter[]
}

function RecruiterList({ recruiters }: RecruiterListProps) {
  const currentRecruiters = recruiters.slice().sort((a, b) => personNameAsc(a.person, b.person))
  return (
    <div>
      {currentRecruiters.map((recruiter: Recruiter, index: number) => (
        <div
          className={`OrderedList__list-item${itemSuffix(
            index === 0,
            index === recruiters.length - 1,
          )}`}
          key={recruiter.id}
        >
          <div className="OrderedList__list-item-content">
            <div className="w-full grid-cols-[auto_1fr_auto] gap-x-2 grid">
              <Avatar person={recruiter.person} size="sm" />

              <div className="flex-col flex">
                <PersonName person={recruiter.person} />
                <PersonTitle person={recruiter.person} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export { RecruiterList }
