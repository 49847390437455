import { AnimatePresence } from "framer-motion"
import React, { useEffect, useState } from "react"

import { ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import RootProvider from "v2/react/components/RootProvider"
import { LoadingIndicator } from "v2/react/shared/loaders/LoadingIndicator"
import { useGetProfilePanelPermissionsQuery } from "v2/redux/GraphqlApi"
import { useGetProfilePlanSettingsQuery } from "v2/redux/GraphqlApi/PeopleApi"
import { closeProfilePlanSidebar, setIsOfficialChart } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { usePersonPositionLoader } from "./hooks/usePersonPositionLoader"
import PersonTabContent from "./PersonTabContent"
import PositionTabContent from "./PositionTabContent"
import { ProfilePanelHeader } from "./ProfilePanelHeader"
import { ProfilePanelSettings } from "./Settings"

interface ProfilePanelProps {
  /**
   * True, except for cases with the panel being shown the org chart page and the official chart is
   * not being shown.
   * */
  isOfficialChart: boolean
  pjaxReloadContainer?: string
  pjaxReloadUrl?: string
}

function ProfilePanelInner({
  pjaxReloadContainer,
  pjaxReloadUrl,
  isOfficialChart = true,
}: ProfilePanelProps) {
  const [showSettings, setShowSettings] = useState(false)
  const dispatch = useAppDispatch()
  const personId = useAppSelector((state) => state.profilePanel.personId)
  const positionId = useAppSelector((state) => state.profilePanel.positionId)

  const activeMode = useAppSelector((state) => state.profilePanel.profilePanelMode)
  const { data: ppSettingsData } = useGetProfilePlanSettingsQuery({})

  const abilityParams = {
    positionId: positionId ? `position_${positionId}` : null,
    personId: personId ? `person_${personId}` : null,
  }
  const { data: abilitiesData } = useGetProfilePanelPermissionsQuery(abilityParams)
  const abilities: ProfilePanelPermissions | undefined = abilitiesData?.profilePanelPermissions

  // This is needed and used as an effect to support updating non-react UI.
  const { person, position, queryPersonTrigger, queryPositionTrigger } = usePersonPositionLoader(
    personId,
    positionId,
  )

  useEffect(() => {
    dispatch(setIsOfficialChart(isOfficialChart))
  }, [isOfficialChart, dispatch])

  // The order here is:
  //   1) Use what the the user has selected from an explicit person/position
  //      click, from the redux slice
  //   2) Otherwise use the person's setting
  //   3) Otherwise use default to the Position mode
  const settingsPanelMode: ProfilePanelMode =
    ppSettingsData?.currentPerson?.settings?.profilePanelSettings?.profilePanelMode ||
    ProfilePanelMode.Position
  const panelMode: ProfilePanelMode = activeMode || settingsPanelMode

  const handleV2Close = () => {
    dispatch(closeProfilePlanSidebar())
  }

  const showLoading = person === undefined || !abilities

  return (
    <div className="react-profile-panel pb-4">
      {showSettings && (
        <ProfilePanelSettings
          onClose={() => setShowSettings(false)}
          mode={panelMode}
          showSettings={showSettings}
        />
      )}
      {!showSettings && (
        <>
          <ProfilePanelHeader
            abilities={abilities}
            handleOpenSettings={() => setShowSettings(true)}
            handleV2Close={handleV2Close}
            isOfficialChart={isOfficialChart}
            panelMode={panelMode}
            person={person}
            position={position}
          />
          {showLoading ? (
            <LoadingIndicator isLoading={showLoading} />
          ) : (
            <AnimatePresence>
              {panelMode === ProfilePanelMode.Person && (
                <PersonTabContent
                  abilities={abilities}
                  person={person}
                  personQuery={queryPersonTrigger}
                />
              )}
              {panelMode === ProfilePanelMode.Position && (
                <PositionTabContent
                  abilities={abilities}
                  pjaxReloadContainer={pjaxReloadContainer}
                  pjaxReloadUrl={pjaxReloadUrl}
                  positionQuery={queryPositionTrigger}
                />
              )}
            </AnimatePresence>
          )}
        </>
      )}
    </div>
  )
}

function ProfilePanel({ isOfficialChart, pjaxReloadContainer, pjaxReloadUrl }: ProfilePanelProps) {
  return (
    <RootProvider>
      <ProfilePanelInner
        isOfficialChart={isOfficialChart}
        pjaxReloadContainer={pjaxReloadContainer}
        pjaxReloadUrl={pjaxReloadUrl}
      />
    </RootProvider>
  )
}
export { ProfilePanel }
