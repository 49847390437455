import { ControlProps, JsonSchema7 } from "@jsonforms/core"
import React, { ChangeEvent, FC } from "react"

import { useValidation } from "./hooks/useValidation"
import { InputErrorText } from "./InputErrorText"
import { oneOfDataItem } from "./types"

const JsonMultiSelectInput: FC<ControlProps> = ({
  config,
  data,
  id,
  handleChange,
  label,
  path,
  schema,
}) => {
  const items = schema.items as JsonSchema7
  const options: oneOfDataItem[] = items.oneOf || []
  const { showError, errorMessage } = useValidation({
    data,
    path,
    schema,
    submitting: config.submitting,
  })

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>, constValue: string) => {
    const selected = event.target.checked ? addItem(data, constValue) : removeItem(data, constValue)
    handleChange(path, selected)
  }

  return (
    <div className="input-group">
      <label>{label}</label>
      {options.map((option, index: number) => (
        <div key={option.const} className={index !== options.length - 1 ? "mb-1" : ""}>
          <label htmlFor={`${path} ${option.const}`} className="checkbox mb-0">
            <input
              type="checkbox"
              value={option.const}
              id={`${path} ${option.const}`}
              name={id}
              checked={data.includes(option.const)}
              onChange={(event) => handleChangeEvent(event, option.const as string)}
            />
            {option.title}
          </label>
        </div>
      ))}
      <InputErrorText message={errorMessage} showError={showError} />
    </div>
  )
}

export { JsonMultiSelectInput }

const addItem = (data: string[], constValue: string) => [...data, constValue]

const removeItem = (data: string[], constValue: string) =>
  data.filter((item) => item !== constValue)
