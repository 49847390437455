import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TFunction } from "i18next"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import {
  Maybe,
  Person,
  Position,
  ProfilePanelPermissions,
  UpdateProfilePanelModeSettingInput,
} from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ArrowNavigator } from "v2/react/components/orgChart/ProfilePanel/ArrowNavigator"
import { EllipsisDropdown } from "v2/react/components/orgChart/ProfilePanel/EllipsisDropdown"
import { EmailIcon } from "v2/react/components/orgChart/ProfilePanel/Icons/Emailcon"
import { PhoneIcon } from "v2/react/components/orgChart/ProfilePanel/Icons/PhoneIcon"
import { SocialLink } from "v2/react/components/orgChart/ProfilePanel/Icons/SocialLink"
import { StickyHeader } from "v2/react/components/orgChart/ProfilePanel/StickyHeader"
import { IS_V2 } from "v2/react/components/orgChart/ProfilePanel/utils/constants"
import { AvatarWithUpload } from "v2/react/shared/icons/AvatarWithUpload"
import { pjaxModalFor, pjaxReload } from "v2/react/utils/pjax"
import { useGetCurrentPersonQuery } from "v2/redux/GraphqlApi"
import { useUpdateProfilePlanModeSettingMutation } from "v2/redux/GraphqlApi/PeopleApi"
import {
  closeProfilePlanSidebar,
  setPersonId,
  setPositionId,
  setProfilePanelMode,
} from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { constructSiblingsNavigationList } from "./utils/constructSiblingsNavigationList"

type ExtendedPerson = Person & { positionId: string }

interface PersonHeaderProps {
  abilities: ProfilePanelPermissions
  onUpdate: (input: { personId: string }) => void
}

function PersonHeader({ abilities, onUpdate }: PersonHeaderProps) {
  const { data: currentPerson, isLoading } = useGetCurrentPersonQuery({})
  const person = useAppSelector((state) => state.profilePanel.person)
  const personId = useAppSelector((state) => state.profilePanel.personId)
  const position = useAppSelector((state) => state.profilePanel.position)
  const positionId = useAppSelector((state) => state.profilePanel.positionId)
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const isCurrentPerson = person?.id === currentPerson?.currentPerson?.id
  const dispatch = useAppDispatch()
  const panel = document.getElementById("side-panel__profile") as HTMLDivElement
  const panelRef = useRef<HTMLDivElement>(panel)
  const headerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const [updateMode] = useUpdateProfilePlanModeSettingMutation()

  useEffect(() => {
    if (!person || person?.id !== personId || !position || position.id !== positionId) return
    if (!IS_V2) {
      window.App.OrgChart.switchPeople(Number(position.id), Number(person.id))
      window.App.OrgChart.focus(Number(position.id))
    }
  }, [person, personId, position, positionId])

  if (isLoading || !person) return null

  const isTopNode = !IS_V2
    ? window.App.OrgChart.getNodeByPosition(Number(position?.id))?.attributes.depth === 0
    : false

  const handlePersonNavigation = (item: ExtendedPerson) => {
    if (!position?.id) return
    dispatch(setPersonId(item.id))
    dispatch(setPositionId(item.positionId))
  }

  const selectPosition = () => {
    dispatch(setProfilePanelMode(ProfilePanelMode.Position))
    const input: UpdateProfilePanelModeSettingInput = {
      profilePanelMode: ProfilePanelMode.Position,
    }
    updateMode(input)
  }

  const { phone, tooltip } = determinePhoneNumber(
    person.workCellPhone,
    person.officePhone,
    person.officePhoneExt,
  )

  const peopleList = position && !IS_V2 ? constructPersonNavigationList(position) : []

  const handleClose = () => {
    if (IS_V2) {
      dispatch(closeProfilePlanSidebar())
    }
  }

  const handleUpdateStatus = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    pjaxModalFor(`/people/${person?.id}/edit/update_status`)
    if (IS_V2) {
      // The preceding lines have been ported over and left as-is. Before the
      // profile panel was enabled outside the org chart, some v2 logic in
      // webpack/v2/modals/person-update-status.js contains code that conflicts
      // with the modal close handling here. This is a temporary workaround to
      // make sure that support remains for any given update status modal. The
      // modal logic also relies on the class "profile-header-info" being
      // present in the html elements below.
      const { jQuery: $, Modal } = window
      if ($ && Modal) {
        $.onmount(Modal.selector("person-update-status"), () => {
          $(Modal.selector("person-update-status")).on("modal:save", () => {
            onUpdate({ personId: person.id })
          })
        })
      }
    }
  }

  const handleSendInvitation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    pjaxModalFor(`/people/${person?.id}/messages/new?invite=true`)
    if (IS_V2) {
      const { jQuery: $, Modal } = window
      if ($ && Modal) {
        $.onmount(Modal.selector("person-send-invitation"), () => {
          const $modalEl = $(Modal.selector("person-send-invitation"))
          $modalEl.on("modal:save", () => {
            onUpdate({ personId: person.id })
            // See: webpack/v2/modals/person-send-invitation.js
            // This code essentially overrides that behavior and avoids using
            // a full reloadTargetContainer on the parent content.
            if ($modalEl.data("modal-after-url")) {
              pjaxReload({
                url: $modalEl.data("modal-after-url"),
                container: "[data-pjax-container=modal-content]",
                fragment: "[data-pjax-container=modal-content]",
              })
            }
          })
        })
      }
    }
  }

  const nameAndMenuContent = () => (
    <div className="items-center gap-4 flex">
      <h1
        data-pjax-container="profile-header-info"
        className="title no-margin profile-header-info flex-none text-large"
      >
        {person.fullName}
      </h1>
      {((isCurrentPerson && abilities.canManageOwnLogin) ||
        abilities.canManageSubordinateLogin) && (
        <EllipsisDropdown>
          <>
            {!isCurrentPerson && (
              <a
                href={`/people/${person?.id}/messages/new?invite=true`}
                className="dropdown-menu-link"
                data-feature="multiple_users"
                data-action={IS_V2 ? undefined : "send-invitation"}
                onClick={handleSendInvitation}
              >
                {determineInvitationText(person, t)}
              </a>
            )}
            {person.userId && determineLoginEditLink(abilities, person, t)}
            {abilities.canManagePerson && !isCurrentPerson && (
              <a
                href={`people/${person?.id}/edit/update_status`}
                className="dropdown-menu-link cursor-pointer"
                data-action={IS_V2 ? undefined : "update-status"}
                onClick={handleUpdateStatus}
              >
                {t("v2.profile_panel.change_employee_status")}
              </a>
            )}
          </>
        </EllipsisDropdown>
      )}
    </div>
  )

  return (
    <>
      <StickyHeader containerRef={panelRef} elementRef={headerRef}>
        <div className="w-full items-center justify-between flex">
          <div className="person-header items-center gap-2 flex">
            <div className="items-center flex">
              <h1
                data-pjax-container="profile-header-info"
                className="title no-margin profile-header-info flex-none text-large"
              >
                {person.fullName}
              </h1>
            </div>
          </div>
          <div className="btn--icon btn--ghost">
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={["far", "times"]}
              data-action={IS_V2 ? undefined : "hide-panel"}
              onClick={IS_V2 ? handleClose : undefined}
            />
          </div>
        </div>
      </StickyHeader>

      <div className="react-profile-panel__person-profile gap-4 flex" ref={headerRef}>
        <AvatarWithUpload
          personId={personId}
          thumbUrl={person?.avatarThumbUrl}
          canUploadAvatar={abilities.canEditPerson || isCurrentPerson}
        />
        <div className="w-full flex-col gap-2 flex">
          <div className="w-full gap-2 flex">
            {nameAndMenuContent()}
            {!IS_V2 && (
              <ArrowNavigator
                items={peopleList}
                disableButtons={
                  peopleList.length === 1 ||
                  !["three_level", "tree"].includes(displayMode) ||
                  isTopNode
                }
                currentItemIndex={peopleList.findIndex(
                  (item: ExtendedPerson) =>
                    item.id === person.id && item.positionId === position?.id,
                )}
                selectItem={handlePersonNavigation}
                tooltipLookup={(item: ExtendedPerson) => item?.name}
              />
            )}
          </div>

          <div className="profile-links items-center gap-4 flex">
            {person?.workEmail && <EmailIcon email={person.workEmail} />}
            {phone && tooltip && <PhoneIcon phone={phone} phoneTooltip={tooltip} />}
            {person.bioSocialLinks?.map((link) => <SocialLink socialLink={link} key={link.url} />)}
          </div>
          {position && (
            <button type="button" className="badge--link hover" onClick={selectPosition}>
              {position?.title || t("v2.profile_panel.untitled_position")}
              <FontAwesomeIcon icon={["far", "chevron-right"]} className="text-primary-50" />
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export { PersonHeader }

const determinePhoneNumber = (
  cell: Maybe<string> | undefined,
  office: Maybe<string> | undefined,
  ext: Maybe<string> | undefined,
) => {
  if (!cell && !office) return { phone: null, tooltip: null }
  if (cell) return { phone: cell, tooltip: cell }
  if (office && ext) return { phone: `${office}, ${ext}`, tooltip: `${office} ext. ${ext}` }
  return { phone: office, tooltip: office }
}

const determineInvitationText = (person: Person, t: TFunction) => {
  if (person.userId) return t("v2.profile_panel.invitation_status")
  if (person.invitationSentAt) return t("v2.profile_panel.resend_invitation")
  return t("v2.profile_panel.send_invitation")
}

const determineLoginEditLink = (
  abilites: ProfilePanelPermissions,
  person: Person,
  t: TFunction,
) => {
  if (abilites.canManageOwnLogin) {
    return (
      <a href="/user/edit" className="dropdown-menu-link">
        {t("v2.profile_panel.manage_login_info")}
      </a>
    )
  }
  if (abilites.canManageSubordinateLogin) {
    return (
      <a
        href={`/users/${person.userId}/edit`}
        className="dropdown-menu-link cursor-pointer"
        onClick={(event) => {
          event.preventDefault()
          pjaxModalFor(`/users/${person.userId}/edit`)
        }}
      >
        {t("v2.profile_panel.manage_login_info")}
      </a>
    )
  }

  return <span />
}

/*
 * This maps over all sibling positions + their people and returns a
 * flattened array of sibling people. Because the array is given to
 * us seemingly in order of the 'id' attribute, it doesn't accurately
 * reflect which person/position would be the next position. To determine
 * this, we need to get the 'x' attribute from each node and order them
 * smallest to largest
 */
const constructPersonNavigationList = (position: Position) => {
  const withPositionId = (node: ExtendedPerson) => node.positionId
  const siblings = (position.siblings || [])
    .map(({ id, people }) => (people || []).map((person) => ({ ...person, positionId: id })))
    .flat()

  return constructSiblingsNavigationList<ExtendedPerson>(position, siblings, withPositionId)
}
