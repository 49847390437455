import cn from "classnames"
import React from "react"

import { Person } from "types/graphql"

interface Props {
  person: Person
  size?: "sm" | "med" | "lg"
}

function Avatar({ person, size }: Props) {
  return (
    person.avatarThumbUrl && (
      <img
        className={cn("circle m-0", {
          "circle-sm": size === "sm",
          "circle-med": size === "med",
          "circle-lg": size === "lg",
        })}
        src={person.avatarThumbUrl}
        alt={person.name}
      />
    )
  )
}

export { Avatar }
