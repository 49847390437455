import React from "react"
import { useTranslation } from "react-i18next"

import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import {
  TitleBlockLarge,
  TitleHeaderWithParent,
} from "v2/react/shared/navigation/PageNav/TitleBlock"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"

interface Props {
  exportUrl?: string | null
  isOpen: boolean
  parentTitle: string | null
  parentTitleUrl?: string
  title: string
}

function PositionsSubPageNav({ exportUrl, isOpen, parentTitle, parentTitleUrl, title }: Props) {
  const { t } = useTranslation()
  return (
    <PageNav>
      <TitleBlockLarge>
        <TitleHeaderWithParent
          parentTitle={parentTitle}
          parentTitleUrl={parentTitleUrl}
          title={title || t("v2.positions.show.untitled_position")}
        />
      </TitleBlockLarge>

      <ActionBlock>
        {isOpen && (
          <StatusBadge displayType="button" fontSizeClass="text-sm" status="open_position" />
        )}
        {exportUrl && <ExportButton exportUrl={exportUrl} useCurrentSearchParamsWithExportUrl />}
      </ActionBlock>
    </PageNav>
  )
}

export { PositionsSubPageNav }
