import { Maybe } from "graphql/jsutils/Maybe"
import React from "react"

import { ApproverDate } from "v2/react/components/approvals/ApproverList/ApproverDate"
import { ApproverNote } from "v2/react/components/approvals/ApproverList/ApproverNote"
import { PersonName } from "v2/react/components/approvals/ApproverList/PersonName"
import { PersonTitle } from "v2/react/components/approvals/ApproverList/PersonTitle"
import { Avatar } from "v2/react/shared/Avatar"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import { ApproverType, initialStatuses, NoteType } from "v2/react/shared/status/statusUtils"

interface ApproverCardProps {
  approver: ApproverType
  isNextApprover: boolean
  notes: NoteType[] | ApproverType[]
  workflowRequestDate: Date
  workflowStatus: Maybe<string> | undefined
}

function ApproverCard({
  approver,
  isNextApprover,
  notes,
  workflowRequestDate,
  workflowStatus,
}: ApproverCardProps) {
  const createStatusDisplay = (approver: ApproverType, isNextApprover: boolean) => {
    if (
      ((approver.status && !approver.status) || (approver.state && !approver.state)) &&
      !isNextApprover
    )
      return <span />
    if (isNextApprover && workflowStatus && initialStatuses.includes(workflowStatus))
      return <StatusBadge badgeClasses="badge--sm" displayType="button" status="pending" />
    if (approver.status || approver.state !== "pending_approval") {
      return (
        <StatusBadge
          badgeClasses="badge--sm"
          checkmarkOnApproved
          displayType="button"
          status={approver.status || approver.state}
        />
      )
    }
    return <span />
  }

  return (
    <div className="w-full grid-cols-[auto_1fr_auto] gap-x-2 grid">
      <Avatar person={approver.person} size="sm" />

      <div className="flex-col flex">
        <PersonName person={approver.person} />
        <PersonTitle person={approver.person} />
      </div>

      <div className="flex-col items-end gap-1 flex">
        {createStatusDisplay(approver, isNextApprover)}
        {workflowStatus !== "canceled" && (
          <ApproverDate
            approver={approver}
            isNextApprover={isNextApprover}
            workflowRequestDate={workflowRequestDate}
            workflowStatus={workflowStatus}
          />
        )}
      </div>
      <ApproverNote
        approver={approver}
        layoutClasses="col-span-3 max-h-40 overflow-auto mt-2"
        notes={notes}
      />
    </div>
  )
}

export { ApproverCard }
