import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { isNil } from "lodash"
import React, { forwardRef, useState } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"

interface PercentInputProps {
  defaultValue?: string | undefined | number
  value?: string | undefined | number
  errors?: string
  id: string
  inputClassName?: string
  label?: string
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  showErrorMessage?: boolean
  useAttentionState?: boolean
  wrapperClassName?: string
}

const PercentInput = forwardRef<HTMLInputElement, PercentInputProps>(
  (
    {
      defaultValue,
      value,
      errors,
      id,
      inputClassName,
      label,
      name,
      onChange,
      placeholder = "",
      showErrorMessage = true,
      useAttentionState,
      wrapperClassName,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const [inputValue, setInputValue] = useState<string>(
      isNil(defaultValue) ? "" : `${defaultValue}`,
    )

    const showAttention = useAttentionState && !isFocused && !errors && !inputValue.trim()

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
      onChange?.(e)
    }

    const inputProps = {
      ref,
      type: "text",
      className: classNames("input suffix-pad", inputClassName),
      placeholder,
      id,
      name,
      onChange: handleChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
    }

    return (
      <InputWrapper
        label={label}
        id={id}
        errors={errors}
        showErrorMessage={showErrorMessage}
        className={wrapperClassName}
      >
        <div
          className={classNames("relative", {
            active: isFocused,
            attention: showAttention,
          })}
        >
          <div className="suffix">
            <FontAwesomeIcon icon={["far", "percent"]} />
          </div>
          {value === undefined ? (
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
              defaultValue={isNil(defaultValue) ? "" : `${defaultValue}`}
            />
          ) : (
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
              value={value}
            />
          )}
        </div>
      </InputWrapper>
    )
  },
)

export { PercentInput }
