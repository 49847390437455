import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useState } from "react"

interface AccordionModulePanelProps {
  headerContent: React.ReactNode
  children: React.ReactNode
  additionalToggleAction?: () => void
  collapsed?: boolean
  reverseToggle?: boolean
  overlayContent?: React.ReactNode
  showOverlay?: boolean
  title: string
}

export const ACCCORDION_DURATION = 0.4

function AccordionModulePanel({
  additionalToggleAction,
  collapsed,
  headerContent,
  children,
  reverseToggle,
  overlayContent,
  showOverlay,
  title,
}: AccordionModulePanelProps) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed ?? false)
  const [height, setHeight] = useState<string | number>("auto")

  useEffect(() => {
    if (overlayContent && showOverlay) {
      const overlayPanel = document.getElementsByClassName("accordion-module-panel__overlay")[0]
      if (overlayPanel) {
        setHeight(overlayPanel.getBoundingClientRect().height)
      }
    } else {
      setHeight("auto")
    }
  }, [overlayContent, showOverlay])

  const handlePanelToggle = () => {
    setIsCollapsed(!isCollapsed)
    if (additionalToggleAction) {
      additionalToggleAction()
    }
  }

  const headerClasses = classNames(
    "flex justify-between items-center w-full accordion-module-panel__header",
    { "flex-row-reverse justify-start": reverseToggle },
  )

  return (
    <motion.div
      className="accordion-module-panel overflow-hidden"
      style={{ boxSizing: showOverlay ? "content-box" : "border-box" }}
      transition={{ duration: ACCCORDION_DURATION }}
      animate={{ height }}
      id={`module-${title}`}
    >
      {overlayContent}
      <div className={headerClasses}>
        {headerContent}
        <button
          className="accordion-module-panel__header-icon header-toggle"
          onClick={handlePanelToggle}
          type="button"
        >
          <motion.span
            initial={false}
            animate={{ rotate: isCollapsed ? -90 : 0 }}
            transition={{ duration: ACCCORDION_DURATION }}
          >
            <FontAwesomeIcon
              className="m-0 cursor-pointer"
              icon={["far", "chevron-down"]}
              size="1x"
            />
          </motion.span>
        </button>
      </div>
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: ACCCORDION_DURATION, ease: [0.04, 0.62, 0.23, 0.98] }}
            className="accordion-module-panel__content"
          >
            <motion.div
              key="content-items"
              variants={{ collapsed: { scale: 0.9 }, open: { scale: 1 } }}
              transition={{ duration: ACCCORDION_DURATION }}
              className="accordion-module-panel__content-items"
            >
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export { AccordionModulePanel }
