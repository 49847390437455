import React from "react"

import { PositionNestedJobRequisition } from "types/graphql"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"

type InternalReqInfoProps = {
  requisitions: PositionNestedJobRequisition[]
}

function InternalReqInfo({ requisitions }: InternalReqInfoProps) {
  return (
    <div className="w-full">
      {requisitions.map((req) => (
        <div key={req.id}>
          <div className="mb-4 justify-between flex ">
            <div>Req ID</div>
            <div>{req.systemUid}</div>
          </div>
          <div className="mb-4 justify-between flex">
            <div>Req Approval State</div>
            <div>
              {req.approvalState ? (
                <StatusBadge status={req.approvalState} displayType="button" />
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export { InternalReqInfo }
