import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JsonForms } from "@jsonforms/react"
import { JsonFormsStyleContext, vanillaCells } from "@jsonforms/vanilla-renderers"
import React from "react"

import { Header } from "v2/react/components/jobRequisitions/Overview/Header/Header"
import { RequisitionApprovalBar } from "v2/react/components/jobRequisitions/Overview/RequisitionApprovalBar"
import { RightSection } from "v2/react/components/jobRequisitions/Overview/RightSection/RightSection"
import { SidebarModals } from "v2/react/components/jobRequisitions/Overview/SidebarModals"
import { viewRenderers } from "v2/react/components/jobRequisitions/renderers"
import { RequisitionModal } from "v2/react/components/jobRequisitions/RequisitionModal"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import { useGetJobRequisitionQuery } from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { setEditApproversModalOpen } from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch } from "v2/redux/store"
import { StoreProvider } from "v2/redux/StoreProvider"

const styleContextValue = { styles: [] }
interface Props {
  abilities: JobRequisitionAbilities
  jobRequisitionId: string
}

function OverviewInner({ abilities, jobRequisitionId }: Props) {
  const { data, isLoading } = useGetJobRequisitionQuery({ id: jobRequisitionId })
  const dispatch = useAppDispatch()

  const showApproversModal = () => {
    dispatch(setEditApproversModalOpen(true))
  }

  if (isLoading || !data?.jobRequisition) return null

  return (
    <>
      <Header
        abilities={abilities}
        data={data.jobRequisition.jsonFormValues.data}
        jobRequisition={data.jobRequisition}
      />

      <div className="requisition-overview page-pad !pb-0">
        {data.jobRequisition.approvalState === "no_configuration" && (
          <div className="alert-critical page-margin-b gap-1.5 flex">
            <FontAwesomeIcon icon={["far", "exclamation-circle"]} className="mt-1" />
            <div>
              <p className="text-base-bold">{"no_configuration".t("status_defaults")}</p>
              <p>{"no_configuration_subheading".t("job_requisition")}</p>
            </div>
            {!window.gon.limited_or_nonadmin_manager && (
              <button
                className="btn--large btn--secondary ml-auto"
                onClick={showApproversModal}
                type="button"
              >
                {"configure_approvers".t("job_requisition")}
              </button>
            )}
          </div>
        )}
        <RequisitionApprovalBar
          workflowStatus={data.jobRequisition.approvalState || ""}
          creatorName={data.jobRequisition.creator?.name}
          approvers={data.jobRequisition.approvers || []}
        />

        <div className="page-gap grid-cols-1 grid 900:grid-cols-[2fr_1fr]">
          <div className="order-2 900:order-1">
            <JsonFormsStyleContext.Provider value={styleContextValue}>
              <div className="create-req-form flex-col p-0 flex">
                <JsonForms
                  schema={data.jobRequisition.jsonFormValues.data_schema}
                  uischema={data.jobRequisition.jsonFormValues.ui_table_schema}
                  data={data.jobRequisition.jsonFormValues.data}
                  renderers={viewRenderers}
                  cells={vanillaCells}
                />
              </div>
            </JsonFormsStyleContext.Provider>
          </div>
          <div className="order-1 900:order-2">
            <RightSection
              abilities={abilities}
              approvalState={data.jobRequisition.approvalState || ""}
              approvers={data.jobRequisition.approvers || []}
              atsIdentifier={data.jobRequisition.atsIdentifier || ""}
              clientIdentifier={data.jobRequisition.clientIdentifier || ""}
              externalStatus={data.jobRequisition.status || ""}
              jobRequisitionId={data.jobRequisition.id || ""}
              notificationRecipients={data.jobRequisition.notificationRecipients || []}
              recruiters={data.jobRequisition.recruiters || []}
              systemUid={data.jobRequisition.systemUid || ""}
              title={data.jobRequisition.jobTitle || ""}
              workflowDate={data.jobRequisition.createdAt}
            />
          </div>
        </div>
      </div>
      <SidebarModals
        approvers={data.jobRequisition.approvers || []}
        recruiters={data.jobRequisition.recruiters || []}
        jobRequisitionId={data.jobRequisition.id || ""}
        notificationRecipients={data.jobRequisition.notificationRecipients || []}
      />
      <RequisitionModal
        data={data.jobRequisition.jsonFormValues.data}
        uiSchema={data.jobRequisition.jsonFormValues.ui_schema}
        dataSchema={data.jobRequisition.jsonFormValues.data_schema}
        uiTableSchema={data.jobRequisition.jsonFormValues.ui_table_schema}
        edit
        jobRequisition={data.jobRequisition}
      />
    </>
  )
}

const Overview = ({ abilities, jobRequisitionId }: Props) => (
  <StoreProvider>
    <OverviewInner abilities={abilities} jobRequisitionId={jobRequisitionId} />
  </StoreProvider>
)

export { Overview }
