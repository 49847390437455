import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { PositionsDestroyPositionInput } from "types/graphql"
import { DestroyPositionMode } from "types/graphql.enums"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"
import { pjaxReload } from "v2/react/utils/pjax"
import { useDestroyPositionMutation } from "v2/redux/GraphqlApi/PositionsApi"
import { closeProfilePlanSidebar } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch } from "v2/redux/store"

import { Confirm } from "./Confirm"
import { Subordinates } from "./Subordinates"

interface RemovePositionModalProps {
  editingDisabled: boolean
  hasChildren: boolean
  isHeadcountPlanner: boolean
  isOfficialChart: boolean
  isOpen: boolean
  onClose: () => void
  pjaxReloadContainer?: string
  pjaxReloadUrl?: string
  positionId: string
}

const RemovePositionModal = ({
  hasChildren,
  isOpen,
  onClose,
  pjaxReloadContainer,
  pjaxReloadUrl,
  positionId,
  editingDisabled = false,
  isHeadcountPlanner = false,
  isOfficialChart = false,
}: RemovePositionModalProps) => {
  const [selectedRemoveOption, setSelectedRemoveOption] = useState<DestroyPositionMode | null>(null)
  const [destroyPosition] = useDestroyPositionMutation()
  const { t } = useTranslation()
  const [permanentlyDelete, setPermanentlyDelete] = useState(false)
  const [showChildrenOptions, setShowChildrenOptions] = useState(hasChildren)
  const dispatch = useAppDispatch()

  const togglePermanentlyDelete = () => {
    setPermanentlyDelete(!permanentlyDelete)
  }

  const handleSubmit = async () => {
    if (hasChildren && showChildrenOptions) {
      setShowChildrenOptions(false)
      return
    }

    const input: PositionsDestroyPositionInput = {
      mode: selectedRemoveOption || DestroyPositionMode.NoChildren,
      positionId,
      permanent: permanentlyDelete,
    }
    await destroyPosition(input).unwrap()
    dispatch(closeProfilePlanSidebar())

    if (pjaxReloadUrl && pjaxReloadContainer) {
      pjaxReload({
        url: pjaxReloadUrl,
        container: pjaxReloadContainer,
      })
    }
  }

  const shouldPreventSubmit = (): boolean => {
    if (hasChildren && showChildrenOptions) {
      return selectedRemoveOption === null
    }

    return isHeadcountPlanner || editingDisabled
  }

  const handleClose = () => {
    setPermanentlyDelete(false)
    setSelectedRemoveOption(null)
    setShowChildrenOptions(false)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("v2.profile_panel.remove_position")}
      footer={
        <ModalFooter
          disabled={shouldPreventSubmit()}
          onClose={handleClose}
          onSave={handleSubmit}
          saveButtonText={
            permanentlyDelete
              ? t("v2.profile_panel.permanently_delete")
              : t("v2.profile_panel.remove_position")
          }
          useCriticalButton
        />
      }
    >
      <AnimatePresence>
        {showChildrenOptions ? (
          <motion.div variants={opaqueBasic} initial="hidden" animate="shown" exit="hidden">
            <Subordinates
              onSelect={setSelectedRemoveOption}
              selectedRemoveOption={selectedRemoveOption}
            />
          </motion.div>
        ) : (
          <motion.div variants={opaqueBasic} initial="shown" animate="shown" exit="hidden">
            <Confirm
              isApprovalPending={editingDisabled}
              isHeadcountPlanner={isHeadcountPlanner}
              isOfficialChart={isOfficialChart}
              togglePermanentlyDelete={togglePermanentlyDelete}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  )
}

export { RemovePositionModal }
