import classNames from "classnames"
import React, { RefObject, useId, useRef } from "react"

import type { Maybe } from "types/graphql"
import { TextInput } from "v2/react/shared/forms/TextInput"

type Translations = {
  cancel: string
  codeLabel: string
  nameLabel: string
  save: string
  saving: string
}

interface FieldsetFooterProps {
  onCancel?: () => void
  onSavePress: () => void
  saving: boolean
  translations: Translations
}

interface AddOrgUnitFieldsetProps {
  className?: string
  errors?: { code?: string; name?: string }
  fieldsContainerClassName?: string
  fieldsetRef?: RefObject<HTMLFieldSetElement>
  generalError?: Maybe<string>
  initialData?: { code?: string; name?: string }
  layoutSpacing?: "condensed" | "normal"
  onCancel?: () => void
  onSave: (data: { name: string; code: string; orgUnitTypeId: string }) => void
  orgUnitTypeId: string
  renderFooter?: (props: FieldsetFooterProps) => React.ReactElement
  saving: boolean
  translations: Translations
}

/** @public */
function AddOrgUnitFieldset({
  className,
  errors = {},
  fieldsContainerClassName,
  fieldsetRef,
  generalError,
  initialData = { code: "", name: "" },
  layoutSpacing = "normal",
  onCancel,
  onSave,
  orgUnitTypeId,
  renderFooter,
  saving,
  translations,
}: AddOrgUnitFieldsetProps) {
  const id = useId()
  const nameRef = useRef<HTMLInputElement>(null)
  const codeRef = useRef<HTMLInputElement>(null)
  const handleSave = () =>
    onSave({
      code: codeRef.current?.value ?? "",
      name: nameRef.current?.value ?? "",
      orgUnitTypeId,
    })
  const spacingClass = layoutSpacing === "condensed" ? "gap-4" : "gap-6"
  const baseClasses = classNames("flex flex-col", spacingClass)

  return (
    <fieldset className={classNames(baseClasses, className)} ref={fieldsetRef}>
      {generalError ? <div className="alert alert-critical !my-0">{generalError}</div> : null}
      <div className={classNames(baseClasses, fieldsContainerClassName)}>
        <TextInput
          autoFocus
          defaultValue={initialData.name}
          errors={errors.name}
          id={`${id}-name`}
          inputRef={nameRef}
          label={translations.nameLabel}
          showErrorMessage={!!errors.name}
        />
        <TextInput
          defaultValue={initialData.code}
          errors={errors.code}
          id={`${id}-code`}
          inputRef={codeRef}
          label={translations.codeLabel}
          showErrorMessage={!!errors.code}
        />
      </div>

      {(renderFooter ?? FieldsetFooter)({
        onCancel,
        onSavePress: handleSave,
        saving,
        translations,
      })}
    </fieldset>
  )
}

/** @private */
const FieldsetFooter = ({ onCancel, onSavePress, saving, translations }: FieldsetFooterProps) => (
  <div className="mt-2 justify-end gap-2 flex">
    <button
      id="cancel-add-org-unit-action"
      className={classNames("btn btn--secondary", { disabled: saving })}
      disabled={saving}
      onClick={onCancel}
      type="button"
    >
      {translations.cancel}
    </button>
    <button
      className={classNames("btn btn--primary", { disabled: saving })}
      disabled={saving}
      id="add-org-unit-action"
      onClick={onSavePress}
      type="button"
    >
      {saving ? translations.saving : translations.save}
    </button>
  </div>
)

export { AddOrgUnitFieldset, AddOrgUnitFieldsetProps, FieldsetFooterProps }
