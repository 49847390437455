import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { prepareIconClass } from "v2/react/utils/misc"

interface PanelIconTooltipProps {
  handleOpenProfile: (event: React.MouseEvent) => void
}

const PanelIconTooltip = ({ handleOpenProfile }: PanelIconTooltipProps) => {
  const { t } = useTranslation()

  const iconClass = prepareIconClass("fac sidebar-flip")
  return (
    <Tooltip placement="bottom-start">
      <TooltipTrigger>
        <div className="invisible ml-1 mt-1 group-hover:visible">
          <button
            className="bg-transparent text-neutral-80"
            type="button"
            onClick={handleOpenProfile}
          >
            <FontAwesomeIcon icon={iconClass} />
          </button>
        </div>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        <span>{t("v2.succession.info_panel")}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export { PanelIconTooltip }
