import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { MouseEvent, useRef } from "react"
import { useTranslation } from "react-i18next"
import ReactModal from "react-modal"

type ModalSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"

interface ModalProps {
  children: React.ReactNode
  className?: string
  contentClassName?: string
  contentRef?: React.RefObject<HTMLDivElement>
  headerClassName?: string
  id?: string
  isOpen: boolean
  onAfterOpen?: () => void
  onBackActionClick?: () => void
  onClose: (event: React.MouseEvent) => void
  overlayClassName?: string
  size?: ModalSize
  testId?: string
  title: string
  titleComponent?: React.ReactNode
  footer?: React.ReactNode
  onContentScroll?: React.UIEventHandler<HTMLDivElement>
  overlayRef?: React.RefObject<HTMLDivElement>
  shouldReturnFocusAfterClose?: boolean
  showBackAction?: boolean
  showCloseButton?: boolean
}

function Modal({
  children,
  className,
  contentClassName,
  contentRef,
  headerClassName,
  id,
  isOpen,
  onAfterOpen,
  onBackActionClick,
  onClose,
  onContentScroll,
  overlayRef,
  testId,
  title,
  titleComponent,
  footer,
  shouldReturnFocusAfterClose,
  showBackAction,
  overlayClassName = "",
  showCloseButton = true,
  size = "sm",
}: ModalProps) {
  const overlayElement = (
    props: React.ComponentPropsWithRef<"div">,
    contentElement: React.ReactElement,
  ) => (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <div {...props} ref={overlayRef}>
      {contentElement}
    </div>
  )

  return (
    // @ts-ignore See: https://github.com/reactjs/react-modal/issues/960
    <ReactModal
      ariaHideApp={false}
      className={classNames(`react-modal__container react-modal--${size}`, className)}
      closeTimeoutMS={250}
      contentLabel={title}
      id={id}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={classNames(`react-modal__overlay`, overlayClassName)}
      shouldCloseOnOverlayClick={false}
      onAfterOpen={onAfterOpen}
      overlayElement={(props, contentElement) => overlayElement(props, contentElement)}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      testId={testId}
    >
      <div className={classNames("react-modal__header", headerClassName)}>
        {titleComponent ?? (
          <div className="items-center gap-4 text-large flex">
            {showBackAction ? (
              <>
                <button onClick={onBackActionClick} type="button" className="btn--ghost mx-[-6px]">
                  <FontAwesomeIcon className="icon-16" icon={["far", "arrow-left"]} />
                </button>
                <div className="h-9 w-px bg-neutral-8" />
              </>
            ) : null}
            {title}
          </div>
        )}
        {showCloseButton && (
          <button className="react-modal__header-close-button" onClick={onClose} type="button">
            <FontAwesomeIcon className="icon-16" icon={["far", "times"]} />
          </button>
        )}
      </div>
      <div
        className={classNames("react-modal__content", contentClassName)}
        onScroll={onContentScroll}
        ref={contentRef}
      >
        {children}
      </div>
      {footer && <div className="react-modal__footer">{footer}</div>}
    </ReactModal>
  )
}

const useModalOverlayRef = () => {
  const modalRef = useRef<HTMLDivElement>(null)

  const scrollToTop = (topElement?: HTMLElement) => {
    if (topElement) {
      // NOTE: Relies on a scroll-margin-top css styling
      // Example: You may want to scroll to an input element within a modal when
      // an error occurs and this hook is used.
      topElement.scrollIntoView({ behavior: "smooth" })
    } else {
      modalRef.current?.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  return {
    modalRef,
    scrollToTop,
  }
}

interface ModalFooterProps {
  cancelButtonText?: string
  disabled?: boolean
  onClose: () => void
  onSave: (event: MouseEvent<HTMLButtonElement>) => Promise<void> | void
  saveButtonText?: string
  useCriticalButton?: boolean
}

const ModalFooter = ({
  cancelButtonText,
  disabled,
  onClose,
  onSave,
  saveButtonText,
  useCriticalButton = false,
}: ModalFooterProps) => {
  const { t } = useTranslation()
  const cancelLabel = cancelButtonText ?? t("v2.defaults.cancel")
  const saveLabel = saveButtonText ?? t("v2.defaults.save")

  const saveButtonClassName = useCriticalButton ? "btn--critical" : "btn--primary"
  return (
    <div className="items-center gap-2 flex">
      <button
        type="button"
        onClick={onClose}
        className={classNames("btn--large btn--secondary", { "btn-disabled": disabled })}
        disabled={disabled}
      >
        {cancelLabel}
      </button>
      <button
        type="submit"
        className={classNames("btn--large", { "btn-disabled": disabled }, saveButtonClassName)}
        onClick={onSave}
        disabled={disabled}
      >
        {saveLabel}
      </button>
    </div>
  )
}

export { Modal, ModalSize, ModalFooter, ModalFooterProps, useModalOverlayRef, ModalProps }
export * from "./Modal/SaveFooter"
