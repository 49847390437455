import cn from "classnames"
import fp from "lodash/fp"
import React, {
  RefObject,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"
import { useOnClickOutside } from "usehooks-ts"

import RootProvider from "v2/react/components/RootProvider"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"
import { useAppSelector } from "v2/redux/store"

import {
  AutocompleteControl,
  AutocompleteControlHandle,
} from "./EmbeddedPositionTypeControl/AutocompleteControl"
import { AnimatedJobCodeAndTitleForm } from "./EmbeddedPositionTypeControl/JobCodeAndTitleForm"
import { useAddPositionTypeForm } from "./hooks/useAddPositionTypeForm"
import { usePositionTypesAutocompleteQueryWithState } from "./hooks/usePositionTypesAutocompleteQueryWithState"

type ControlActions =
  | { type: "beganInteractingWithAutocomplete" }
  | { type: "canceledCreateNewPositionType" }
  | { type: "changedSearchTerm"; payload: string }
  | { type: "choseCreateNewPositionType" }
  | { type: "choseExistingPositionType"; payload: PositionTypesConnectionNode }
  | { type: "createdPositionType"; payload: PositionTypesConnectionNode }
  | { type: "createPositionTypeFailed" }
  | { type: "exited" }
  | { type: "replacedPositionType"; payload?: PositionTypesConnectionNode }
// The embedded control orchestrates transitions between various states. Due to
// this, it can't quite be used as a "controlled" component. This handle is
// exposed via a ref so callers can change values.
//
// NOTE: `replacePositionType` is a NOP if the user is interacting with the
// field.
type ControlHandle = {
  currentPositionType?: PositionTypesConnectionNode
  replacePositionType: (node?: PositionTypesConnectionNode) => void
}
type ControlState = {
  currentPositionType?: PositionTypesConnectionNode
  // Only set when the component first mounts. It's expected that the caller
  // reloads the page on submit (or remounts this).
  initialPositionType?: PositionTypesConnectionNode
  keepCurrentOnExit: boolean
  searchTerm: string
  state: "entered" | "inactive" | "usingAutocomplete" | "usingCreateForm"
}

type ControlActionFns = {
  beganInteractingWithAutocomplete: () => void
  canceledCreateNewPositionType: () => void
  changedSearchTerm: (searchTerm: string) => void
  createPositionTypeFailed: () => void
  createdPositionType: (created: PositionTypesConnectionNode) => void
  exited: () => void
  madeChoice: (choice: ControlActions) => void
  replacedPositionType: (node?: PositionTypesConnectionNode) => void
}
type ControlDomEventsApi = ReturnType<typeof useEmbeddedControlDomEvents>
type ControlDomRefs = ReturnType<typeof useEmbeddedControlRefs>

type EmbeddedPositionTypeControlProps = {
  className?: string
  controlRef?: React.RefObject<ControlHandle>
  createIsVirtual?: boolean
  disabled?: boolean
  fieldFeedback?: React.ReactNode
  idForIdInput?: string
  idForJobCodeTitleLabelInput?: string
  initialValue?: PositionTypesConnectionNode
  inputClassName?: string
  label?: string
  nameForIdInput?: string
  nameForJobCodeTitleLabelInput?: string
  onChange?: (maybeNode: PositionTypesConnectionNode | undefined) => void
  testId?: string
}

type UseInlineCreateMutationArg = {
  actions: ControlActionFns
  createIsVirtual: boolean
  autocompleteControlRef: ControlDomRefs["autocompleteControlRef"]
  setShowList: (val: boolean) => void
}

function WithProviders({
  className,
  createIsVirtual = false,
  controlRef,
  disabled,
  fieldFeedback,
  idForIdInput = "position_position_type_id",
  idForJobCodeTitleLabelInput = "position_position_type_attributes_job_code_title_label",
  initialValue,
  inputClassName,
  label,
  nameForIdInput = "position[position_type_id]",
  nameForJobCodeTitleLabelInput = "position[position_type_attributes][job_code_title_label]",
  onChange,
  testId,
}: EmbeddedPositionTypeControlProps) {
  const id = useId()
  const refs = useEmbeddedControlRefs()
  const domEvents = useEmbeddedControlDomEvents(refs.containerRef)

  const {
    actions: {
      beganInteractingWithAutocomplete,
      canceledCreateNewPositionType,
      changedSearchTerm,
      createdPositionType,
      exited,
      madeChoice,
      replacedPositionType,
      setShowList,
    },
    state: {
      canShowList,
      canUseOptions,
      currentPositionType,
      fieldError,
      positionTypes,
      searchTerm,
      state,
    },
  } = useEmbeddedControlApi(domEvents, refs, createIsVirtual, initialValue)

  useImperativeHandle(
    controlRef,
    () => ({
      currentPositionType,
      replacePositionType: (node) => {
        if (state !== "inactive")
          // eslint-disable-next-line no-console
          console.warn("Replacing a position type while the control is in use is a NOP")

        replacedPositionType(node)
      },
    }),
    [currentPositionType, replacedPositionType, state],
  )

  const onChangeRef = useRef(onChange)
  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])
  useEffect(() => onChangeRef.current?.(currentPositionType), [currentPositionType])

  useOnClickOutside(refs.containerRef, (ev) => {
    const { current: autocompleteControl } = refs.autocompleteControlRef

    const isControlActive = state !== "inactive"
    const element = ev.target
    const isHTMLElement = element instanceof HTMLElement
    const isOutsideOfAutocomplete = isHTMLElement && !autocompleteControl?.contains(element)

    if (isControlActive && isOutsideOfAutocomplete) exited()
  })

  return (
    <div
      className={cn("input-group relative !mb-6 flex-1", fieldError && "form-error", className)}
      id={id}
      data-testid={testId}
      ref={refs.containerRef}
    >
      <input
        type="hidden"
        id={idForIdInput}
        name={nameForIdInput}
        value={currentPositionType?.uniqueKey ? idFromUniqueKey(currentPositionType.uniqueKey) : ""}
      />
      <AutocompleteControl
        canShowList={canShowList}
        canUseOptions={canUseOptions && state !== "usingCreateForm" && state !== "entered"}
        controlRef={refs.autocompleteControlRef}
        disabled={disabled}
        id={idForJobCodeTitleLabelInput}
        inputClassName={inputClassName}
        label={label}
        name={nameForJobCodeTitleLabelInput}
        onChoice={madeChoice}
        onFocus={beganInteractingWithAutocomplete}
        onSearchTermChange={changedSearchTerm}
        options={positionTypes}
        floatingPortalId={id}
        searchTerm={searchTerm}
        setShowList={setShowList}
      />
      {state === "usingCreateForm" ? (
        <AnimatedJobCodeAndTitleForm
          className={cn(
            "InlinePositionTypeForm",
            "w-[23rem] rounded-lg bg-white block",
            "px-4 pb-3 pt-4",
            "elevation--overlay absolute top-[72px] z-50",
          )}
          createIsVirtual={createIsVirtual}
          initialData={getInitialData(searchTerm)}
          onCancel={canceledCreateNewPositionType}
          onSaved={createdPositionType}
        />
      ) : null}

      {fieldError && <InputErrorText message={fieldError} />}
      {fieldFeedback}
    </div>
  )
}

function EmbeddedPositionTypeControl(props: EmbeddedPositionTypeControlProps) {
  return (
    <RootProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WithProviders {...props} />
    </RootProvider>
  )
}

// -- Internal Hooks

const useEmbeddedControlRefs = () => ({
  autocompleteControlRef: useRef(null) as AutocompleteControlHandle,
  containerRef: useRef<HTMLDivElement>(null),
})

const useEmbeddedControlDomEvents = (containerRef: RefObject<HTMLDivElement>) =>
  useMemo(
    () => ({
      changedPositionTypeSearch: (searchTerm: string) =>
        containerRef.current?.dispatchEvent(
          new CustomEvent<string>("changedPositionTypeSearch", {
            bubbles: true,
            detail: searchTerm,
          }),
        ),
      createdOrSelectedPositionType: (positionTypeNode: PositionTypesConnectionNode) =>
        containerRef.current?.dispatchEvent(
          new CustomEvent<PositionTypesConnectionNode>("createdOrSelectedPositionType", {
            bubbles: true,
            detail: positionTypeNode,
          }),
        ),
    }),
    [containerRef],
  )

function useEmbeddedControlApi(
  domEvents: ControlDomEventsApi,
  { autocompleteControlRef }: ControlDomRefs,
  createIsVirtual: boolean,
  initial?: PositionTypesConnectionNode,
) {
  const initialState = buildInitialEmbeddedControlState(initial)

  const [canShowList, setShowList] = useState(false)
  const [state, dispatch] = useReducer(withLogging(positionTypeFormStateReducer), initialState)
  const initialPositionType = state.initialPositionType

  const actions = useMemo(
    () => ({
      beganInteractingWithAutocomplete: () => {
        dispatch({ type: "beganInteractingWithAutocomplete" })
        setShowList(true)
      },
      canceledCreateNewPositionType: () => {
        dispatch({ type: "canceledCreateNewPositionType" })
        autocompleteControlRef.current?.focus()
      },
      changedSearchTerm: (searchTerm: string) => {
        dispatch({ type: "changedSearchTerm", payload: searchTerm })
        domEvents.changedPositionTypeSearch(searchTerm)
      },
      createPositionTypeFailed: () => {
        dispatch({ type: "createPositionTypeFailed" })
      },
      createdPositionType: (created: PositionTypesConnectionNode) => {
        dispatch({ type: "createdPositionType", payload: created })
        domEvents.createdOrSelectedPositionType(created)
      },
      exited: () => dispatch({ type: "exited" }),
      madeChoice: (choice: ControlActions) => {
        dispatch(choice)

        if (choice.type !== "choseExistingPositionType") return

        autocompleteControlRef.current?.focus()
        if (choice.payload.uniqueKey === initialPositionType?.uniqueKey) return

        domEvents.createdOrSelectedPositionType(choice.payload)
      },
      replacedPositionType: (node?: PositionTypesConnectionNode) =>
        dispatch({ type: "replacedPositionType", payload: node }),
    }),
    [dispatch, domEvents, initialPositionType, autocompleteControlRef],
  )

  const { actions: withCreateActions, state: withCreateState } = useInlineCreateMutation({
    actions,
    autocompleteControlRef,
    createIsVirtual,
    setShowList,
  })

  const { canUseOptions, positionTypes } = usePositionTypesAutocompleteQueryWithState({
    currentTerm: state.currentPositionType?.jobCodeTitleLabel ?? "",
    searchTerm: state.searchTerm,
  })

  return {
    actions: {
      ...actions,
      ...withCreateActions,
      setShowList,
    },
    dispatch,
    state: {
      ...state,
      ...withCreateState,
      canShowList,
      canUseOptions,
      positionTypes,
    },
  }
}

// -- Helpers

const buildInitialEmbeddedControlState = (initial?: PositionTypesConnectionNode): ControlState => ({
  currentPositionType: initial,
  initialPositionType: initial,
  keepCurrentOnExit: true,
  searchTerm: initial?.jobCodeTitleLabel ?? "",
  state: "inactive",
})

function getInitialData(searchTerm: string) {
  const codeNamePair = fp.split(/\s*-\s*/, searchTerm)

  const head = fp.head(codeNamePair) ?? ""
  const tail = fp.pipe(fp.tail, fp.join(" - "))(codeNamePair)

  return !tail && head ? { jobCode: "", title: head } : { jobCode: head, title: tail }
}

function positionTypeFormStateReducer(state: ControlState, action: ControlActions): ControlState {
  switch (action.type) {
    case "beganInteractingWithAutocomplete":
    case "canceledCreateNewPositionType":
      return { ...state, state: "usingAutocomplete" }
    case "changedSearchTerm":
      return {
        ...state,
        currentPositionType: undefined,
        keepCurrentOnExit: false,
        searchTerm: action.payload,
      }
    case "choseCreateNewPositionType":
      return { ...state, state: "usingCreateForm" }
    case "createPositionTypeFailed":
      return { ...state, state: "usingAutocomplete", keepCurrentOnExit: true }
    case "choseExistingPositionType":
    case "createdPositionType":
      return {
        ...state,
        currentPositionType: action.payload,
        keepCurrentOnExit: true,
        searchTerm: action.payload.jobCodeTitleLabel,
        state: "entered",
      }
    case "exited":
      return state.keepCurrentOnExit
        ? { ...state, state: "inactive" }
        : { ...state, currentPositionType: undefined, searchTerm: "", state: "inactive" }
    case "replacedPositionType":
      return state.state === "inactive"
        ? {
            ...state,
            currentPositionType: action.payload,
            searchTerm: action.payload?.jobCodeTitleLabel ?? "",
          }
        : state
    default:
      return state
  }
}

// -- Throw away

/* eslint-disable no-console */
function withLogging(reducer: (state: ControlState, action: ControlActions) => ControlState) {
  if (process.env.NODE_ENV !== "development") return reducer

  return (state: ControlState, action: ControlActions) => {
    console.group(
      `EmbeddedPositionTypeControl %c${action.type}`,
      "background: rgba(027, 098, 255, 1); color: #fff; padding: 2px 4px; border-radius: 2px; font-weight: 300;",
    )
    console.log(" %cprior:", "font-weight: 900; padding: 4px; ", state)
    console.log(
      "%caction:",
      "font-weight: 900; padding: 4px; font-family: 'Consolas', monospace !important;",
      action,
    )
    const result = reducer(state, action)
    console.log(
      "  %cnext:",
      "font-weight: 900; padding: 4px; font-family: 'Consolas', monospace !important",
      result,
    )
    console.groupEnd()

    return result
  }
}
/* eslint-enable no-console */

/**
 * Prepares a mutation function that can be used to create a new position type
 * immediately without going through the popover form.
 *
 * At time of writing, this is only used to support customers who do not have
 * position management or succession planning feature flags. Otherwise create is
 * managed by `JobCodeAndTitleForm`.
 */
const useInlineCreateMutation = ({
  actions,
  autocompleteControlRef,
  createIsVirtual,
  setShowList,
}: UseInlineCreateMutationArg) => {
  const featureFlags = useAppSelector((state) => state.session.featureFlags)
  const canUseForm = featureFlags?.positionManagement || featureFlags?.successionPlanning

  const {
    errors,
    save,
    state: { reset, ...state },
  } = useAddPositionTypeForm(createIsVirtual)
  const fieldError = errors.getFieldError("title") ?? errors.getFieldError("job_code_title_label")

  // Wraps our standard `madeChoice` action and conditionally deals with
  // creating a position type inline.
  const madeChoiceWithInlineCreate = useCallback(
    async (choice: ControlActions) => {
      const { madeChoice, createdPositionType, createPositionTypeFailed } = actions

      const shouldForwardChoice = canUseForm || choice.type !== "choseCreateNewPositionType"
      if (shouldForwardChoice) return madeChoice(choice)

      const title = autocompleteControlRef.current?.getValue?.() ?? ""
      const result = await save({ title, jobCodeTitleLabel: title })

      setShowList(false)
      if (!result.success) {
        createPositionTypeFailed()
        return result
      }

      // If here, we were successful. We manually blur the input to reproduce
      // the typical experience that occurs when the popover form is used for
      // creation.
      createdPositionType(result.positionTypeNode)
      autocompleteControlRef.current?.blur?.()
      return result
    },
    [autocompleteControlRef, actions, canUseForm, save, setShowList],
  )

  // Special handler to reset mutation state, primarily to clear errors. We
  // normally don't handle errors at this level. Otherwise just forwards.
  const changedSearchTermWithErrorReset = useCallback(
    (searchTerm: string) => {
      if (fieldError) reset()
      actions.changedSearchTerm(searchTerm)
    },
    [actions, fieldError, reset],
  )

  return {
    state: {
      fieldError,
      createState: { ...state, reset },
      canUseForm,
      featureFlags,
    },
    actions: {
      changedSearchTerm: changedSearchTermWithErrorReset,
      madeChoice: madeChoiceWithInlineCreate,
    },
  }
}

export { ControlHandle, EmbeddedPositionTypeControl }
