import React, { useCallback, useEffect, useState } from "react"
import { useDebounce } from "usehooks-ts"

import { PeopleConnectionQuery, Person } from "types/graphql"
import { AUTOCOMPLETE_LIMIT } from "v2/react/constants"
import { useLazySearchPeopleQuery } from "v2/redux/GraphqlApi"

interface Props {
  omitValues: string[]
  fieldValue?: string
  chartId?: string
  dataFetchFn?: () => PeopleConnectionQuery
}

function usePersonSearch({ omitValues, fieldValue, chartId, dataFetchFn }: Props) {
  const [showResultList, setShowResultList] = useState(false)
  const [inputValue, setInputValue] = useState(fieldValue || "")
  const debouncedInputValue = useDebounce(inputValue, 350)
  const [peopleResult, setPeopleResult] = useState<Person[]>([])
  const [fallbackSearch] = useLazySearchPeopleQuery()

  const loadResult = useCallback(
    (people: Person[]) => {
      const peopleToShow: Person[] = people.filter((p) => !(omitValues || []).includes(p.id))
      setPeopleResult(peopleToShow)
    },
    [omitValues, setPeopleResult],
  )

  const searchPeople = useCallback(
    async (searchValue: string) => {
      if (!searchValue || searchValue === "") return

      if (dataFetchFn) {
        const result = await dataFetchFn()
        loadResult(result.people?.nodes || [])
      } else {
        const result = await fallbackSearch({
          searchTerm: searchValue,
          first: AUTOCOMPLETE_LIMIT,
          chartId,
        }).unwrap()
        loadResult(result.people?.nodes || [])
      }
      setShowResultList(true)
    },
    [dataFetchFn, fallbackSearch, loadResult, chartId],
  )

  useEffect(
    () => {
      if (debouncedInputValue === "") setShowResultList(false)
      searchPeople(debouncedInputValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedInputValue],
  )

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
  }

  return {
    showResultList,
    setShowResultList,
    inputValue,
    setInputValue,
    peopleResult,
    handleInputChange,
    selectedIndex: peopleResult?.findIndex((pr) => pr.name === inputValue),
  }
}

export { usePersonSearch }
