import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { colorWheel } from "v2/react/components/successionSettings/palettes"
import { closeColorPicker } from "v2/redux/slices/VisualizationSlice"
import { selectColorFieldByActiveTab } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { asyncUpdateColorInCode } from "v2/redux/slices/VisualizationSlice/visualizationThunks"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

const noop = () => undefined

function ColorPicker() {
  const dispatch = useAppDispatch()
  const [colors] = useState(colorWheel)
  const showColorPicker = useAppSelector((state) => state.visualization.showColorPicker)
  const field = useAppSelector(selectColorFieldByActiveTab)
  const type = useAppSelector((state) => state.visualization.activeColorCodingTab)
  const colorCode = useAppSelector((state) => state.visualization.colorPickerColorCode)
  const colorPickerPosition = useAppSelector((state) => state.visualization.colorPickerPosition)
  const colorPickerSelectedColor = useAppSelector(
    (state) => state.visualization.colorPickerSelectedColor,
  )

  // Bind this component with the jQuery colorPalette plugin that we use.
  const colorPaletteRef = useRef(null)
  const afterSubmit = () => {
    if (!colorPaletteRef.current) return

    const color = colorPaletteRef.current.value
    if (!color) return

    dispatch(asyncUpdateColorInCode({ type, field, model: colorCode, color }))
    dispatch(closeColorPicker())
  }

  useEffect(() => {
    if (!colorPaletteRef.current) return

    window.$(colorPaletteRef.current).removeData("plugin_colorPalette")
    window.$(colorPaletteRef.current).colorPalette()
  })

  // Capture clicks outside of the color picker and treat them as a call to
  // close the color picker. Only attach an event listener if this is shown, and
  // detach once not shown.
  const rootRef = useRef(window.document.getRootNode())
  useEffect(() => {
    const refSnapshot = rootRef.current
    if (!showColorPicker || !refSnapshot) return noop

    const listener = () => dispatch(closeColorPicker())
    refSnapshot.addEventListener("click", listener)

    return () => {
      refSnapshot.removeEventListener("click", listener)
    }
  }, [dispatch, showColorPicker])

  if (!showColorPicker) return null

  // TODO: No longer track `left` in state as current CSS class handles this
  // fine.
  return (
    <div
      className="color-picker-tooltip-wrapper"
      style={{ top: colorPickerPosition.top - 208.5 }}
      onClick={preventPropagationOfSyntheticEvent}
    >
      <div className="form-control">
        <a className="modal-close-icon right" onClick={() => dispatch(closeColorPicker())}>
          <FontAwesomeIcon icon={["far", "times"]} />
        </a>
        <label htmlFor="color">{"Select a color".t("calendar")}</label>
        <input
          id="color"
          readOnly
          ref={colorPaletteRef}
          type="text"
          name="color-picker"
          value={colorPickerSelectedColor}
          data-colors={colors.join(",")}
        />
      </div>
      <div className="footer">
        <button
          onClick={() => dispatch(closeColorPicker())}
          type="button"
          className="btn--large btn--secondary"
        >
          {"Cancel".t("defaults")}
        </button>
        <button onClick={afterSubmit} type="submit" className="btn--large btn--primary">
          {"Save".t("defaults")}
        </button>
      </div>
    </div>
  )
}

/**
 * Stop propagation of clicks made within the color picker so the callback
 * attached by the earlier effect doesn't close this. React dispatches synthetic
 * events, so the call to `nativeEvent.stopImmediatePropagation()` is crucial.
 */
const preventPropagationOfSyntheticEvent = (ev) => {
  if (ev && ev.nativeEvent && ev.nativeEvent.stopImmediatePropagation) {
    ev.nativeEvent.stopImmediatePropagation()
  }

  ev.preventDefault()
}

ColorPicker.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
}

export default ColorPicker
