import { JsonSchema } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"
import { ErrorObject } from "ajv"

import { ajv } from "../ajv"

interface Props {
  path: string
  submitting: boolean
  schema: JsonSchema
  data: string | number | undefined
}

const useValidation = ({ data, path, schema, submitting }: Props) => {
  // I am not convinced this is true: "These errors are mixed in with the
  // regular errors coming from the AJV validation."
  // See: https://jsonforms.io/docs/validation/#external-validation-errors
  //
  // Because the errors from the control do not contain the backend errors
  // that we add to additionalErrors, we manually pull any additionalErrors from
  // the provided context.
  // https://jsonforms.discourse.group/t/error-doesnt-show-up-in-custom-renderer/890
  // https://jsonforms.discourse.group/t/custom-field-validation-with-errors-outside-of-jsonforms-and-ajv/442
  const context = useJsonForms()

  // This field is validated within its own control with a different expectation
  // for the format of the data.
  if (path === "position.variablePayTypes") return emptyError()

  const validate = ajv.compile(schema)
  const showSchemaError = submitting && !validate(data) && !!validate.errors
  const allAdditionalErrors = context.core?.additionalErrors || []
  const additionalErrors: ErrorObject[] = lookupAdditionalErrorsByPath(path, allAdditionalErrors)
  const showAdditionalErrors = (additionalErrors || []).length > 0

  if (path.includes("position.variablePayTypes") && path.includes("amount"))
    return variablePayError(additionalErrors, path)

  return {
    showError: showSchemaError || showAdditionalErrors,
    errorMessage: errorMessage(validate.errors || [], additionalErrors),
  }
}

const emptyError = () => ({
  showError: false,
  errorMessage: "",
})

const variablePayError = (additionalErrors: ErrorObject[], path: string) => {
  const pathAsInstancePath = path.replace(/\./g, "/")
  const relevantError = additionalErrors.find(
    (ae: ErrorObject) => ae.instancePath === `/${pathAsInstancePath}`,
  )
  if (relevantError) {
    return {
      showError: true,
      errorMessage: relevantError.message || "",
    }
  }
  return emptyError()
}

const errorMessage = (schemaErrors: ErrorObject[], additionalErrors: ErrorObject[]): string => {
  if (schemaErrors && schemaErrors.length > 0) return formatErrors(schemaErrors)
  if (additionalErrors.length > 0) return formatErrors(additionalErrors)
  return ""
}

// Handle any errors added by response/backend/manual validations.
const lookupAdditionalErrorsByPath = (
  path: string,
  allAdditionalErrors: ErrorObject[],
): ErrorObject[] =>
  allAdditionalErrors.filter((e) => e.instancePath.replace(/\//g, ".").includes(path))

const formatErrors = (errors: ErrorObject[]): string =>
  errors.reduce(
    (messagesAcc: string, error: ErrorObject) => `${messagesAcc + (error.message || "")} `,
    "",
  )

export { useValidation }
