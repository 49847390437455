import cn from "classnames"
import React from "react"

import { Content } from "v2/react/shared/layout/Sidebar/Content"

interface SidebarProps {
  open: boolean
  children: React.ReactNode
  onAfterClose?: () => void
}

const Sidebar = ({ open, children, onAfterClose }: SidebarProps) => {
  const { utilityNavHeight, totalHeaderHeight } = getOffsets()

  const handleTransitionEnd = () => {
    if (!open) {
      onAfterClose?.()
    }
  }

  return (
    <div
      className={cn({
        drawer: true,
        "is-open": open,
      })}
      style={{
        top: `${utilityNavHeight}px`,
        height: `calc(100vh - ${totalHeaderHeight}px)`,
      }}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="drawer-tab-container h-full bg-white">
        <div className="drawer-contents panel grid-rows-[auto_1fr] grid">{children}</div>
      </div>
    </div>
  )
}

const getOffsets = () => {
  const alertTop: HTMLDivElement | null = document.querySelector(".alert-top")
  const pageNav: HTMLDivElement | null = document.querySelector(".page-nav")
  const utilityNav: HTMLDivElement | null = document.querySelector(".utility-nav")

  let totalHeaderHeight = 0
  let utilityNavHeight = 0
  if (alertTop) {
    totalHeaderHeight += alertTop.offsetHeight
  }
  if (pageNav) {
    totalHeaderHeight += pageNav.offsetHeight
  }
  if (utilityNav) {
    totalHeaderHeight += utilityNav.offsetHeight
    utilityNavHeight = utilityNav.offsetHeight
  }

  return {
    utilityNavHeight,
    totalHeaderHeight,
  }
}

Sidebar.Content = Content

export { Sidebar }
