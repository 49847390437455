import { useCallback, useMemo, useState } from "react"

import { PositionsUpdateInput } from "types/graphql"
import { useAllMutationErrors } from "v2/react/hooks/useAllMutationErrors"
import { useFieldSuggestionsActions } from "v2/react/hooks/useFieldSuggestionsActions"
import { usePositionsUpdateMutation } from "v2/redux/GraphqlApi"

function useEditPosition(uniqueKey: string) {
  // Prepare an action to update the position which handles minutiae.
  const [doUpdatePosition, updateState] = usePositionsUpdateMutation()
  const updatePosition = useCallback(
    async (attributes: PositionsUpdateInput["attributes"]) => {
      try {
        const result = await doUpdatePosition({ id: uniqueKey, attributes }).unwrap()
        const anyErrors = result.positionsUpdate?.errors?.length
        return !anyErrors
      } catch {
        // Errors are tracked by the mutation hook; return false to indicate
        // failure
        return false
      }
    },
    [doUpdatePosition, uniqueKey],
  )

  // Extract/normalize errors related to the update action.
  const { data: updateData, error: updateError } = updateState
  const updateErrors = useAllMutationErrors(updateError, updateData?.positionsUpdate?.errors)

  // Prepare actions for managing field suggestions. Handy for combining
  // certain state.
  const { state: suggestionState, ...suggestionActions } = useFieldSuggestionsActions(uniqueKey)
  const suggestionErrors = useAllMutationErrors(
    suggestionState.error,
    suggestionState.data?.fieldSuggestionsPerformActions?.errors,
  )

  // We stitch together errors from three possible places:
  // 1. usePositionsUpdate
  // 2. Backing mutations from suggestion actions
  // 3. Errors from socket communication
  const [socketErrors, setSocketErrors] = useState<string[]>([])

  const addSocketError = useCallback(
    (error: string) =>
      setSocketErrors((errors) => (errors.includes(error) ? errors : [...errors, error])),
    [setSocketErrors],
  )

  const { reset: resetSuggestionState } = suggestionState
  const { reset: resetUpdatePositionState } = updateState
  const resetAll = useCallback(() => {
    setSocketErrors([])
    resetSuggestionState()
    resetUpdatePositionState()
  }, [setSocketErrors, resetSuggestionState, resetUpdatePositionState])

  const allErrors = useMemo(
    () => [...socketErrors, ...updateErrors, ...suggestionErrors],
    [socketErrors, suggestionErrors, updateErrors],
  )

  return {
    allErrors,
    isBusy: suggestionState.isLoading || updateState.isLoading,
    suggestionState,
    updateErrors,
    updateState,

    addSocketError,
    cancelGeneratingFieldSuggestions: suggestionActions.cancelGeneratingFieldSuggestions,
    generateFieldSuggestions: (fieldOrFields: string | string[], timeoutMs?: number) => {
      resetAll()
      return suggestionActions.generateFieldSuggestions(fieldOrFields, timeoutMs)
    },
    updatePosition: (attributes: PositionsUpdateInput["attributes"]) => {
      resetAll()
      return updatePosition(attributes)
    },
    resetAll,
  }
}

export { useEditPosition }
