import { JsonForms } from "@jsonforms/react"
import { JsonFormsStyleContext, vanillaCells } from "@jsonforms/vanilla-renderers"
import Ajv, { ErrorObject } from "ajv"
import React from "react"

import { JsonForm } from "types/graphql"
import { createRenderers } from "v2/react/components/jobRequisitions/renderers"

import { FormShape } from "./types/FormShape"

const styleContextValue = {
  styles: [
    {
      name: "horizontal.layout",
      classNames: ["horizontal-layout items-start"],
    },
  ],
}

interface Props {
  additionalErrors: ErrorObject[]
  ajv: Ajv
  formData: FormShape
  handleChange: ({ data }: { data: FormShape }) => void
  jsonForm: JsonForm
  submitting: boolean
}

function ModalFormContent({
  additionalErrors,
  ajv,
  formData,
  handleChange,
  jsonForm,
  submitting,
}: Props) {
  if (!jsonForm) return null

  return (
    <JsonFormsStyleContext.Provider value={styleContextValue}>
      <div className="create-req-form flex-col flex">
        <JsonForms
          additionalErrors={additionalErrors}
          schema={jsonForm.schema.data_schema}
          uischema={jsonForm.schema.ui_schema}
          data={formData}
          renderers={createRenderers}
          cells={vanillaCells}
          ajv={ajv}
          onChange={handleChange}
          config={{ submitting }}
        />
      </div>
    </JsonFormsStyleContext.Provider>
  )
}

export { ModalFormContent }
