import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LayoutGroup, motion } from "framer-motion"
import React, { useState } from "react"

import { generateConditionalColors } from "v2/react/components/successionSettings/matrixUtils"
import { colorWheel } from "v2/react/components/successionSettings/palettes"
import { preventPropagationOfSyntheticEvent } from "v2/react/hooks/useOutsideClick"
import { ColorPicker } from "v2/react/shared/forms/ColorPicker"
import { CardType, GridItem, setLabelErrors } from "v2/redux/slices/MatrixSlice"
import { useAppDispatch } from "v2/redux/store"

interface Props {
  item: GridItem
  cardStyle: CardType
  onUpdateItemLabel: (item: GridItem, newLabel: string) => void
  onUpdateItemColor: (item: GridItem, newColor: string) => void
}

function MatrixGridItem({ item, cardStyle, onUpdateItemLabel, onUpdateItemColor }: Props) {
  const dispatch = useAppDispatch()
  const [showColorPicker, setShowColorPicker] = useState(false)

  const afterSubmit = (color: string) => {
    onUpdateItemColor(item, color)
  }

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker)
  }

  const closeColorPicker = () => {
    setShowColorPicker(false)
  }

  const handleLabelChange = (e: React.FormEvent<HTMLInputElement>) => {
    checkForError(e.currentTarget.value.trim())
    onUpdateItemLabel(item, e.currentTarget.value.trim())
  }

  const checkForError = (value: string) => dispatch(setLabelErrors(value.length === 0))

  return (
    <motion.div
      className="matrix__grid-item matrix__grid-item-customize"
      initial={false}
      animate={generateConditionalColors(cardStyle, item.color)}
    >
      <LayoutGroup>
        <motion.div
          layout="size"
          transition={{
            type: "spring",
            stiffness: 800,
            damping: 60,
            duration: 0.2,
            delayChildren: 0.5,
          }}
          className="flex-1"
        >
          <input
            defaultValue={item.label || ""}
            name={`item-${item.id}`}
            onChange={handleLabelChange}
            className="input matrix__grid-item-title"
          />
        </motion.div>
        {cardStyle !== "color_none" && (
          <motion.div
            layout
            transition={{
              type: "spring",
              stiffness: 800,
              damping: 60,
              duration: 0.2,
            }}
            className="customize__color-wrapper"
            onClick={preventPropagationOfSyntheticEvent}
          >
            <button
              className="customize__color-header items-center justify-between flex"
              onClick={toggleColorPicker}
              type="button"
            >
              <div className="customize__color-display" style={{ backgroundColor: item.color }} />
              <FontAwesomeIcon icon={["far", "chevron-down"]} />
            </button>
            {showColorPicker && (
              <ColorPicker
                showColorPicker={showColorPicker}
                colors={colorWheel}
                currentColor={item.color}
                onSubmit={afterSubmit}
                closeColorPicker={closeColorPicker}
                pickerPosition={{ top: 40, left: 0 }}
              />
            )}
          </motion.div>
        )}
      </LayoutGroup>
    </motion.div>
  )
}

export { MatrixGridItem }
