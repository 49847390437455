export enum AddonRole {
  Budgeting = 'budgeting',
  Compensation = 'compensation',
  PositionHierarchyManagement = 'position_hierarchy_management'
}

export enum AdpChangeBatchState {
  Busy = 'busy',
  Discarded = 'discarded',
  Open = 'open',
  PausedWithErrors = 'paused_with_errors',
  Sent = 'sent',
  SentWithErrors = 'sent_with_errors'
}

export enum AdpChangeRequestChangeItemLabelFormat {
  None = 'none',
  Translate = 'translate'
}

export enum AdpChangeRequestErrorDescriptionKey {
  AdpServerError = 'adp_server_error',
  InvalidCodeValue = 'invalid_code_value',
  InvalidCustomField = 'invalid_custom_field',
  InvalidCustomFieldIndicator = 'invalid_custom_field_indicator',
  InvalidCustomFieldTelephone = 'invalid_custom_field_telephone',
  InvalidData = 'invalid_data',
  InvalidDate = 'invalid_date',
  InvalidEffectiveDate = 'invalid_effective_date',
  InvalidEffectiveDatePositions = 'invalid_effective_date_positions',
  InvalidField = 'invalid_field',
  InvalidStartDate = 'invalid_start_date',
  NoChange = 'no_change',
  ReportsToMustBeSupervisor = 'reports_to_must_be_supervisor',
  ReportsToMustNotAllowCyclicalReports = 'reports_to_must_not_allow_cyclical_reports',
  Required = 'required',
  Unknown = 'unknown',
  WorkerWorkAssignmentMismatch = 'worker_work_assignment_mismatch'
}

export enum AdpChangeRequestState {
  Discarded = 'discarded',
  Dropped = 'dropped',
  Failed = 'failed',
  FailedCopiedToNextBatch = 'failed_copied_to_next_batch',
  FailedIgnored = 'failed_ignored',
  Queued = 'queued',
  Sending = 'sending',
  Succeeded = 'succeeded'
}

export enum AdpPausedChangeBatchAction {
  /** ignore_errors */
  IgnoreErrors = 'ignore_errors',
  /** roll_over_to_next_batch */
  RollOverToNextBatch = 'roll_over_to_next_batch'
}

export enum AllowedAttributeInputTypeEnum {
  /** datepicker */
  Datepicker = 'datepicker',
  /** dropdown */
  Dropdown = 'dropdown',
  /** forced_autocomplete */
  ForcedAutocomplete = 'forced_autocomplete',
  /** read_only */
  ReadOnly = 'read_only',
  /** standard_editable */
  StandardEditable = 'standard_editable',
  /** suggested_autocomplete */
  SuggestedAutocomplete = 'suggested_autocomplete'
}

export enum AllowedAttributeTypeEnum {
  /** currency */
  Currency = 'currency',
  /** date */
  Date = 'date',
  /** datepicker */
  Datepicker = 'datepicker',
  /** numeric */
  Numeric = 'numeric',
  /** short_text */
  ShortText = 'short_text'
}

export enum ArrangeTrackPositionTypeAction {
  /**
   * Moves the track position type into a new track and updates its sort order.
   * This is distinct from reorder due to updating the career ladder track.
   */
  Move = 'move',
  /** Updates the sort order of a track position type. */
  Reorder = 'reorder'
}

export enum BasePay {
  Hourly = 'hourly',
  Salary = 'salary'
}

export enum Change {
  Create = 'create',
  Destroy = 'destroy',
  Ignore = 'ignore',
  Update = 'update'
}

export enum ChangeTrigger {
  Person = 'person',
  System = 'system',
  Unknown = 'unknown'
}

export enum CompensationChangeMode {
  /** Creates a new compensation record. */
  Append = 'append',
  /** Updates the current compensation (if it exists); otherwise, creates a new compensation record. */
  Upsert = 'upsert'
}

export enum CustomFieldModel {
  Asset = 'Asset',
  Person = 'Person',
  Position = 'Position'
}

export enum DestroyPositionMode {
  /** Removes the position and all descendants beneath it. */
  DestroyAllChildren = 'destroy_all_children',
  /** Removes the position and moves any descendants up in the hierarchy. */
  MoveChildrenUp = 'move_children_up',
  /**
   * We enforce an explicit no children option (as opposed to defaulting to this
   * with an optional param). This assists with UI and code flow.
   */
  NoChildren = 'no_children'
}

export enum FlsaClassification {
  Exempt = 'exempt',
  Nonexempt = 'nonexempt'
}

export enum FieldSuggestionAction {
  Accept = 'accept',
  Decline = 'decline',
  Initialize = 'initialize',
  Regenerate = 'regenerate'
}

export enum FieldSuggestionPersistedState {
  Accepted = 'accepted',
  Declined = 'declined',
  InitializeFailed = 'initialize_failed',
  Initialized = 'initialized',
  Initializing = 'initializing'
}

export enum FieldSuggestionType {
  String = 'string',
  Tags = 'tags'
}

export enum FilterDataType {
  Binary = 'binary',
  Collection = 'collection',
  Currency = 'currency',
  Date = 'date',
  Numeric = 'numeric',
  Percentage = 'percentage'
}

export enum FilterType {
  DateRange = 'date_range',
  Inclusion = 'inclusion',
  Range = 'range'
}

export enum FilterableTables {
  PositionsPage = 'positions_page'
}

export enum FundingSourceAllocation {
  Amount = 'amount',
  PercentOfBudget = 'percent_of_budget'
}

export enum HeadcountPlanChangeProjectionAction {
  /** CreateNew */
  CreateNew = 'create_new',
  /** DestroyExisting */
  DestroyExisting = 'destroy_existing',
  /** UpdateExisting */
  UpdateExisting = 'update_existing'
}

export enum HeadcountPlanChangeProjectionStatus {
  /** Approved */
  Approved = 'approved',
  /** Denied */
  Denied = 'denied',
  /** Pending */
  Pending = 'pending'
}

export enum HeadcountPlanChangeProjectionTypeEnum {
  /** Modified */
  Modified = 'modified',
  /** New */
  New = 'new',
  /** Unchanged */
  Unchanged = 'unchanged'
}

export enum HeadcountPlanParticipantStatus {
  Complete = 'complete',
  InProgress = 'in_progress',
  Invited = 'invited'
}

export enum HeadcountPlanRole {
  /** Collaborator */
  Collaborator = 'collaborator',
  /** Owner */
  Owner = 'owner',
  /** Owner + Participant */
  OwnerAndParticipant = 'owner_and_participant',
  /** Participant */
  Participant = 'participant'
}

export enum HeadcountPlanStatus {
  /** All Plans Submitted */
  AllPlansSubmitted = 'all_plans_submitted',
  /** Plan Approved */
  Approved = 'approved',
  /** In Progress */
  InProgress = 'in_progress'
}

export enum ImportTriggerState {
  Available = 'available',
  ImportBusy = 'import_busy',
  SyncBusy = 'sync_busy',
  SyncPausedWithErrors = 'sync_paused_with_errors',
  SyncPending = 'sync_pending'
}

export enum NotificationTopic {
  BatchEvents = 'batch_events',
  EntityEvents = 'entity_events',
  Processing = 'processing'
}

export enum OrgUnitTypeSystemType {
  BusinessUnit = 'business_unit',
  CostNumber = 'cost_number',
  Department = 'department'
}

export enum PositionReportsWithSettings {
  OpenPositions = 'open_positions',
  PositionAllocations = 'position_allocations',
  PositionCost = 'position_cost',
  PositionFunding = 'position_funding',
  PositionsPage = 'positions_page',
  RemovedPositions = 'removed_positions',
  RetirementRisk = 'retirement_risk'
}

export enum PositionSeatChangeIntent {
  /** Add the person to the position and make it a secondary (if possible) */
  Dotted = 'dotted',
  /** Move the person into this position (from their primary position, if it exists) */
  Move = 'move',
  /** Create a new person and place them in the position, making it their primary */
  NewPerson = 'new_person',
  /** Add the person to the position and make it their primary */
  Primary = 'primary',
  /** Remove the person from the position */
  Remove = 'remove'
}

export enum PositionsOverTimeMetrics {
  Filled = 'filled',
  New = 'new',
  Open = 'open',
  Removed = 'removed',
  Total = 'total',
  TransferredIn = 'transferred_in',
  TransferredOut = 'transferred_out'
}

export enum ProfilePanelMode {
  Person = 'person',
  Position = 'position'
}

export enum Role {
  AssetAdmin = 'asset_admin',
  Budgeting = 'budgeting',
  CompanyManager = 'company_manager',
  Compensation = 'compensation',
  HrAdmin = 'hr_admin',
  LimitedAdmin = 'limited_admin',
  PersonManager = 'person_manager',
  PositionHierarchyManagement = 'position_hierarchy_management',
  ReadOnlyAdmin = 'read_only_admin',
  RecruitingAdmin = 'recruiting_admin'
}

export enum RoleScope {
  Inherit = 'inherit',
  LinkedSubordinates = 'linked_subordinates',
  Organization = 'organization',
  TheirSubordinates = 'their_subordinates'
}

export enum SortDirection {
  /** Ascending order */
  Asc = 'asc',
  /** Descending order */
  Desc = 'desc'
}

export enum SourcePay {
  Amount = 'amount',
  Percent = 'percent'
}

export enum StandardRank {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export enum TerminationEffect {
  Delete = 'delete',
  Open = 'open'
}

export enum TerminationReason {
  Involuntary = 'involuntary',
  Voluntary = 'voluntary'
}

export enum TimelineIntervalTypeEnum {
  /** month */
  Month = 'month',
  /** quarter */
  Quarter = 'quarter',
  /** year */
  Year = 'year'
}

export enum TimelineMetricTypeEnum {
  /** new_budget */
  NewBudget = 'new_budget',
  /** new_count */
  NewCount = 'new_count',
  /** new_fte */
  NewFte = 'new_fte',
  /** total_budget */
  TotalBudget = 'total_budget',
  /** total_count */
  TotalCount = 'total_count',
  /** total_fte */
  TotalFte = 'total_fte'
}

export enum VariablePayFormattedFields {
  Amount = 'amount',
  PayType = 'pay_type'
}

export enum VariablePayModel {
  Compensation = 'Compensation',
  Position = 'Position'
}
