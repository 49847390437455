import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { useMediaQuery } from "usehooks-ts"

interface PanelSidebarProps {
  children: React.ReactNode
  id?: string
  isOpen: boolean
  panelType: PanelSidebarType
}

type PanelSidebarType = "profile" | "succession" | "successionLarge"
type BreakPoints = { is480: boolean; is680: boolean; is1000: boolean }

const PanelSidebar: React.FC<PanelSidebarProps> = ({ children, id, isOpen, panelType }) => {
  const is480 = useMediaQuery("(min-width: 480px)")
  const is680 = useMediaQuery("(min-width: 680px)")
  const is1000 = useMediaQuery("(min-width: 1000px)")
  const breakpoints: BreakPoints = { is480, is680, is1000 }

  const sidebarVariants = {
    open: { x: 0, transition: { duration: 0.4 } },
    closed: { x: "100%", transition: { duration: 0.4 } },
  }

  // It is important that the animated div element has a width of a non-zero
  // value by using the inherited width of the parent. Also, a margin is needed
  // here to let the drop shadow show.
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed bottom-0 right-0 top-0 z-above-modal-panel-sidebar h-screen"
          initial={{ width: 0 }}
          animate={{ width: panelWidth(panelType, breakpoints) }}
        >
          <motion.div
            animate="open"
            className={classNames(elevationStyles(panelType))}
            exit="closed"
            initial="closed"
            variants={sidebarVariants}
          >
            {/* Because of the sticky header, we need to set the height on a
            non-animated parent, otherwise, it will not function as intended.
            */}
            <div className="h-screen min-h-screen overflow-auto bg-white" id={id}>
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

// Animations will not work unless the widths play very nicely here. It is
// important that the animated div element has a width of a non-zero value by
// using the inherited width of the parent. The fixed position plays a role in
// this. Also, using tailwind breakpoint values for widths will cause
// animations to not work. We need to know media types or css variables.
// See: https://samuelkraft.com/blog/responsive-animation-framer-motion
// See: https://www.youtube.com/watch?v=xSuxsfn13xg
// NOTE: This media query approach is not ideal for server side rendering,
// etc. in the future we may need to use some other approach.
//
// We avoid using these:
//   Nope: "max-w-screen w-screen 480:w-[480px]"
//   Nope: "max-w-screen w-screen 1000:w-[680px]"
//   Nope: "max-w-screen w-screen 680:w-[680px] 1000:w-[1000px]"
// In the absence of breakpoints, use explicit widths to appease the animations.
// In the future if we use breakpoints, dynamic values will not work:
//   const staticWidthStyles = `max-w-screen w-screen ${widthBreakpoint}:w-[${width}]`
// https://stackoverflow.com/questions/72889068/template-literal-not-working-correctly-with-tailwind-css
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
const panelWidth = (panelType: PanelSidebarType, breakpoints: BreakPoints): string => {
  if (panelType === "profile" && breakpoints.is480) return "480px"
  if (panelType === "succession" && breakpoints.is680) return "680px"
  if (panelType === "successionLarge" && breakpoints.is1000) return "1000px"
  if (panelType === "successionLarge" && breakpoints.is680) return "680px"
  return "100%"
}

// A margin is needed here to let the drop shadow show
const elevationStyles = (panelType: PanelSidebarType): string => {
  if (panelType === "profile") return "480:elevation--profound  480:ml-4"
  return "680:elevation--profound 680:ml-4"
}

export { PanelSidebar }
