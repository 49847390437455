import dayjs from "dayjs"
import React from "react"

import { NotificationRecipient } from "types/graphql"
import { PersonName } from "v2/react/components/approvals/ApproverList/PersonName"
import { PersonTitle } from "v2/react/components/approvals/ApproverList/PersonTitle"
import { Avatar } from "v2/react/shared/Avatar"
import { itemSuffix } from "v2/react/shared/collection/OrderedList/OrderListItem"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import { personNameAsc } from "v2/react/utils/sorts"

interface RecipientListProps {
  recipients: NotificationRecipient[]
}

function RecipientList({ recipients }: RecipientListProps) {
  const existingRecipients = recipients.slice().sort((a, b) => personNameAsc(a.person, b.person))

  return (
    <div>
      {existingRecipients.map((recipient: NotificationRecipient, index: number) => (
        <div
          className={`OrderedList__list-item${itemSuffix(
            index === 0,
            index === recipients.length - 1,
          )}`}
          key={recipient.person.id}
        >
          <div className="OrderedList__list-item-content">
            <div className="w-full grid-cols-[auto_1fr_auto] gap-x-2 grid">
              <Avatar person={recipient.person} size="sm" />

              <div className="flex-col flex">
                <PersonName person={recipient.person} />
                <PersonTitle person={recipient.person} />
              </div>

              <div className="flex-col items-end gap-1 flex">
                {recipient.notifiedAt && (
                  <StatusBadge status="notified" displayType="button" badgeClasses="badge--sm" />
                )}
                {recipient.notifiedAt && (
                  <p className="ml-auto text-sm text-neutral-64">
                    {dayjs(recipient.notifiedAt).format("MM/DD/YY")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export { RecipientList }
