import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { SuccessionPlanCandidate } from "v2/react/components/orgChart/ProfilePanel/PositionModules/SuccessionPlanCandidate"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { IS_V2 } from "v2/react/components/orgChart/ProfilePanel/utils/constants"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { closeProfilePlanSidebar } from "v2/redux/slices/ProfilePanelSlice"
import { openSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface SuccessionPlanProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function SuccessionPlan({ abilities, featureFlags }: SuccessionPlanProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const position = useAppSelector((state) => state.profilePanel.position)
  const { currentBox, toggleBox } = useSelectedBox("succession_plan", ProfilePanelMode.Position)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  if (!position || !person || !currentBox) return null
  if (!abilities.canReadSuccessionStrategy) return null
  if (!featureFlags.successionPlanning) return null

  const openSuccessionPlan = (positionId: string | undefined) => {
    if (!positionId) return

    if (!IS_V2) {
      const node = window.App.OrgChart.getNodeByPosition(Number(positionId))
      window.App.OrgChart.focus(Number(positionId))
      window.App.OrgChart.viewSuccessionPlan(node)
    } else {
      dispatch(closeProfilePlanSidebar())
      dispatch(openSuccessionPlanSidebar({ positionId }))
    }
  }

  const headerContent = <div className="title">{t("v2.profile_panel.succession_plan")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div className="succession-plan-profile-module">
          <blockquote className="succession-plan-module__candidates">
            <p>
              {"candidates_to_replace".t(
                "succession_plan",
                "module",
                "%d candidates to replace %s",
                position.successionPlan?.candidates?.length || 0,
                [position.successionPlan?.candidates?.length || 0, person.fullName],
              )}
            </p>
            <ul className="no-bullets">
              {position.successionPlan?.candidates?.map((candidate) => (
                <SuccessionPlanCandidate candidate={candidate} key={candidate.id} />
              ))}
            </ul>

            <button
              className={classNames("link-text", { disabled: window.gon.editing_disabled })}
              onClick={() => openSuccessionPlan(position.id)}
              type="button"
            >
              {(abilities.canEditSuccessionPlan ? "edit_plan" : "view_plan").t(
                "succession_plan",
                "module",
              )}
            </button>
          </blockquote>

          <div className="succession-plan-module__target">
            <div>
              <FontAwesomeIcon icon={["fas", "arrow-alt-right"]} />
            </div>
            <div>
              {person.avatarThumbUrl && <img src={person.avatarThumbUrl} alt="Target Avatar" />}
              <p>{position.title}</p>
            </div>
            <div>
              <FontAwesomeIcon icon={["fas", "arrow-alt-right"]} />
            </div>
          </div>

          <div className="succession-plan-module__candidacies">
            {person.candidacies && (
              <div className="list-group">
                <div className="list-group-item">
                  {"candidacies_count".t(
                    "succession_plan",
                    "module",
                    "%s is a candidate in %d succession plans",
                    person.candidacies?.length,
                    [person.fullName, person.candidacies?.length],
                  )}
                </div>

                {person.candidacies.map((candidacy) => (
                  <div className="list-group-item" key={candidacy.id}>
                    <div>
                      <div className="list-group-item-title">
                        {"title".t("succession_plan", "candidacy", null, null, [
                          candidacy.sortOrder,
                        ])}
                      </div>
                      <button
                        className="link-text cursor-pointer"
                        onClick={() => openSuccessionPlan(candidacy.successionPlan?.position?.id)}
                        type="button"
                      >
                        {candidacy.successionPlan?.position?.title}
                      </button>
                      <div className="secondary-text">
                        {`${t("v2.profile_panel.reports_to")} ${candidacy.successionPlan?.position
                          ?.reportsToName}`}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { SuccessionPlan }
